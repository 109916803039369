<template>
  <TableLayout :permissions="['cpointconsume:cpointconsume:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="用户id" prop="userId">
        <el-input v-model="searchForm.userId" placeholder="请输入用户id" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="消费商户名称" prop="merchantName">
        <el-input v-model="searchForm.merchantName" placeholder="请输入消费商户名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="审核编号" prop="auditNo">
        <el-input v-model="searchForm.auditNo" placeholder="请输入审核编号" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <rx-select v-model="searchForm.status" :data-list="[{code:0,label:'未审核'},{code:1,label:'已审核'}]"  ></rx-select>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
      >
        <el-table-column prop="userId" label="用户id" min-width="180px"></el-table-column>
        <el-table-column prop="userPhone" label="手机号" min-width="120px"></el-table-column>
        <el-table-column prop="merchantName" label="消费商户名称" min-width="180px"></el-table-column>
        <el-table-column prop="consumeTime" label="消费时间" min-width="180px"></el-table-column>
        <el-table-column prop="imgs" label="图片" min-width="100px">
          <template slot-scope="{row}">
            <template v-if="!!row.imgs">
              <el-image fit="contain"
                        style="width: 80px; height: 80px"
                        :src="row.imgs.split(',')[0]"
                        :preview-src-list="row.imgs.split(',')">
              </el-image>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="auditNo" label="审核编号" min-width="220px"></el-table-column>
        <el-table-column prop="status" label="状态" min-width="100px">
          <template slot-scope="{row}">
            <span v-if="row.status === 1">已审核</span>
            <span v-if="row.status === 0">未审核</span>
          </template>
        </el-table-column>
        <el-table-column prop="money" label="消费金额" min-width="100px"></el-table-column>
        <el-table-column prop="point" label="赠送积分" min-width="100px"></el-table-column>
        <el-table-column prop="createTime" label="提交时间" min-width="180px"></el-table-column>
        <el-table-column label="操作" width="120px">
          <template slot-scope="{row}">
            <el-button @click="passPoint(row)" v-if="row.status === 0" type="text">审核</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
      >
      </pagination>
    </template>


    <!-- 附件查看 -->
    <el-dialog
        title="审核消费记录"
        :visible.sync="dialogVisible"
        :append-to-body="true"
        width="50%">

      <el-form  ref="form" label-width="120px" v-if="!!passItem" :rules="rules" :model="formObj">
        <el-form-item label="用户id">
          {{passItem.id}}
        </el-form-item>
        <el-form-item label="用户手机号">
          {{passItem.userPhone}}
        </el-form-item>
        <el-form-item label="消费金额"  prop="amount" required>
          <el-input v-model="formObj.amount"></el-input>
        </el-form-item>
        <el-form-item label="获得积分"  prop="point" required>
          <el-input v-model="formObj.point"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="passGo">确 定</el-button>
      </span>
    </el-dialog>

  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
export default {
  name: 'CPointConsume',
  extends: BaseTable,
  components: { TableLayout, Pagination },
  data () {
    return {
      dialogVisible: false,
      passItem: null,
      formObj:{
        id: null,
        amount:null,
        point:null,
      },
      // 搜索
      searchForm: {
        userId: '',
        merchantName: '',
        auditNo: '',
        status: ''
      },
      rules:{
        amount: [
          { required: true, message: '请输入消费金额' }
        ],
        point: [
          { required: true, message: '请输入获得积分' }
        ],
      }
    }
  },
  created () {
    this.config({
      module: '积分消费记',
      api: '/admin/cPointConsume',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  },
  methods:{
    passPoint(row){
      this.passItem = row;
      this.dialogVisible = true;
      this.formObj.id = row.id;
    },
    passGo(){
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        const loading = this.$loading({
          lock: true,
          text: '操作中，请稍后！',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.api.pass(this.formObj).then(res => {
          this.$message.success('操作成功！');
          this.dialogVisible = false;
          this.formObj = {
            id: null,
            amount:null,
            point:null,
          };
          this.handlePageChange();
          this.passItem = null;
        }).finally(() => {
          loading.close();
        })
      });



    }
  }
}
</script>
