<template>
  <TableLayout :permissions="['cnote:cnote:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="用户id" prop="userId">
        <el-input v-model="searchForm.userId" placeholder="请输入用户id" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="noteType">
        <rx-select v-model="searchForm.noteType" :data-list="[{code:1,label:'攻略'},{code:2,label:'笔记'}]"  ></rx-select>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <rx-select v-model="searchForm.status" :data-list="[{code:1,label:'已审核'},{code:0,label:'未审核'}]"  ></rx-select>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
      >
        <el-table-column prop="userId" label="用户id" min-width="180px"></el-table-column>
        <el-table-column prop="noteType" label="类型" min-width="100px">
          <template slot-scope="{row}">
            <span v-if="row.noteType === 1">攻略</span>
            <span v-if="row.noteType === 2">笔记</span>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题" min-width="100px"></el-table-column>
        <el-table-column prop="desc" label="描述" min-width="100px"></el-table-column>
        <el-table-column prop="coverUrl" label="封面" min-width="100px">
          <template slot-scope="{row}">
            <template v-if="!!row.coverUrl">
              <el-image fit="contain"
                        style="width: 80px; height: 80px"
                        :src="row.coverUrl"
                        :preview-src-list="[row.coverUrl]">
              </el-image>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="playType" label="玩法类型" min-width="100px"></el-table-column>
        <el-table-column prop="playTime" label="游玩时长" min-width="100px"></el-table-column>
        <el-table-column prop="userMoney" label="人均成本" min-width="100px"></el-table-column>
        <el-table-column prop="content" label="内容" min-width="100px">
          <template v-slot="{row}">
            <column-detail :content="row.content" :showHtmlFlag="true"></column-detail>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="附件" min-width="100px">
          <template v-slot="{row}">
            <el-button @click="lookNoteFile(row)" v-if="!!row.fileList && row.fileList.length > 0">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="publishTime" label="发布时间" min-width="180px"></el-table-column>
        <el-table-column prop="status" label="状态" min-width="100px">
          <template slot-scope="{row}">
            <span v-if="row.status === 1">已审核</span>
            <span v-if="row.status === 0">未审核</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200px">
          <template slot-scope="{row}">
            <el-button @click="auditNote(row)" v-if="row.status == 0" type="text">审核通过</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
      >
      </pagination>
    </template>



    <!-- 附件查看 -->
    <el-dialog
        title="查看附件"
        :visible.sync="dialogVisible"
        :append-to-body="true"
        width="50%">
      <div v-if="!!currentImgList && currentImgList.length > 0">
        <h2>图片</h2>
        <div class="showImgClass">
          <div class="showImgItemClass" v-for="item in currentImgList" :key="item">
            <el-image fit="contain"
                      :src="item"
                      :z-index="9999"
                      :preview-src-list="[item]">
            </el-image>
          </div>
        </div>
      </div>
      <br/>
      <br/>
      <div v-if="!!currentVideoList && currentVideoList.length > 0">
        <h2>视频</h2>
        <template v-for="item in currentVideoList">
          <video class="showVideoItem" :src="item" controls></video>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import ColumnDetail from '@/components/common/ColumnDetail'
import {auditPass} from "../../api/admin/cNote";
export default {
  name: 'CNote',
  extends: BaseTable,
  components: { TableLayout, Pagination, ColumnDetail },
  data () {
    return {
      // 搜索
      searchForm: {
        userId: '',
        noteType: '',
        status: ''
      },
      dialogVisible: false,
      currentImgList: [],
      currentVideoList: []
    }
  },
  created () {
    this.config({
      module: '用户笔记/攻略表',
      api: '/admin/cNote',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  },
  methods: {
    auditNote (row) {
      this.$dialog.confirmBox('确定审核通过吗？').then(res => {
        this.api.auditPass(row.id).then(res => {
          this.$message.success('操作成功！')
          this.handlePageChange();
        })
      }).catch(res => {

      })
    },
    lookNoteFile (item) {
      this.currentVideoList = []
      this.currentImgList = []
      if (item.fileList) {
        this.dialogVisible = true
        item.fileList.map(item => {
          if (item.type == 1) {
            this.currentImgList.push(item.url)
          } else {
            this.currentVideoList.push(item.url)
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.showImgClass{
  display: flex;
  gap: 20px;
  flex-flow:row wrap
}

.showImgItemClass{
  flex: 0 0 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.showImgItemClass img{
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.showVideoItem{
  width: 300px;
  height: 300px;
  margin: 20px;
}

</style>
