<template>
  <TableLayout :permissions="['cordershop:cordershop:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="用户id" prop="userId">
        <el-input v-model="searchForm.userId" placeholder="请输入用户id" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="收货人姓名" prop="userName">
        <el-input v-model="searchForm.userName" placeholder="请输入收货人姓名" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="收货手机号" prop="mobile">
        <el-input v-model="searchForm.mobile" placeholder="请输入收货手机号" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="平台流水号" prop="platformNo">
        <el-input v-model="searchForm.platformNo" placeholder="请输入平台流水号" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="orderStatus">
        <rx-select v-model="searchForm.orderStatus" type="order_shop_order_type" ></rx-select>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
      >
        <el-table-column prop="userId" label="用户id" width="180px"></el-table-column>
        <el-table-column prop="userName" label="收货人姓名" width="120px"></el-table-column>
        <el-table-column prop="mobile" label="收货手机号" width="120px"></el-table-column>
        <el-table-column prop="detailAddress" label="详细地址" min-width="180px"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="180px"></el-table-column>
        <el-table-column prop="orderStatus" label="订单状态" width="100px">
          <template v-slot="{row}">
            {{getDictName('order_shop_order_type', row.orderStatus)}}
          </template>
        </el-table-column>
        <el-table-column prop="platformNo" label="平台流水号" min-width="260px"></el-table-column>
<!--        <el-table-column prop="paymentType" label="支付方式" min-width="100px">-->
<!--          <template v-slot="{row}">-->
<!--            <span v-if="row.paymentType === 1">微信</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column prop="paymentTime" label="支付时间" min-width="180px"></el-table-column>
        <el-table-column prop="goodsNum" label="商品总数量" min-width="100px"></el-table-column>
        <el-table-column prop="discount" label="优惠券金额" min-width="100px"></el-table-column>
        <el-table-column prop="goodsDiscountPrice" label="优惠总价格" min-width="100px"></el-table-column>
        <el-table-column prop="goodsPayPrice" label="实付金额" min-width="100px"></el-table-column>
        <el-table-column prop="freightPrice" label="运费" min-width="100px"></el-table-column>
        <el-table-column prop="weight" label="订单总重量（g）" min-width="160px"></el-table-column>
        <el-table-column prop="remark" label="买家的备注" min-width="100px"></el-table-column>
        <el-table-column
            label="操作"
            min-width="120"
            fixed="right"
        >
          <template slot-scope="{row}">
            <el-button v-if="row.orderStatus === 7" type="text" @click="$refs.refundAuditWindow.open(row.platformNo, row.id, 2)">审核</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
      >
      </pagination>
    </template>

    <!-- 审核-->
    <RefundAuditWindow ref="refundAuditWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import RefundAuditWindow from '@/components/admin/RefundAuditWindow'
export default {
  name: 'COrderShop',
  extends: BaseTable,
  components: { TableLayout, Pagination, RefundAuditWindow },
  data () {
    return {
      dialogVisible: false,
      // 搜索
      searchForm: {
        userId: '',
        userName: '',
        mobile: '',
        platformNo: '',
        orderStatus: null
      },
      refundRow: null
    }
  },
  created () {
    this.config({
      module: '商城订单信息表',
      api: '/admin/cOrderShop',
      'field.id': 'id',
      'field.main': 'id',
      dictKeys: ['order_shop_order_type']
    })
    this.search()
  },
  methods: {
    auditRefund (row) {
      this.refundRow = row
      this.dialogVisible = true
    }
  }
}
</script>
