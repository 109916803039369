<template>
  <GlobalWindow
      :title="title"
      :visible.sync="visible"
      :confirm-working="isWorking"
      @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="所属模块" prop="module" required>
        <rx-select pre-load v-model="form.module" type="banner_module"  ></rx-select>
      </el-form-item>
      <el-form-item label="标题" prop="title" required>
        <el-input v-model="form.title" placeholder="请输入标题" v-trim/>
      </el-form-item>
      <el-form-item label="二级标题" prop="subTitle">
        <el-input v-model="form.subTitle" placeholder="请输入二级标题" v-trim/>
      </el-form-item>
      <el-form-item label="图片地址" prop="url" required>
        <rx-upload v-model="form.url" type="6"></rx-upload>
      </el-form-item>
      <el-form-item label="点击之后的操作" prop="operate" required>
        <rx-select pre-load v-model="form.operate" type="banner_operate"  ></rx-select>
      </el-form-item>
      <el-form-item label="操作之后的值" prop="operateValue" required>
        <el-input v-model="form.operateValue" placeholder="请输入操作之后的值" v-trim/>
      </el-form-item>
      <el-form-item label="状态" prop="status" required>
        <rx-select pre-load v-model="form.status" :data-list="[{code:1,label:'启用'},{code:0,label:'禁用'}]"  ></rx-select>
      </el-form-item>
      <el-form-item label="排序值（越小越靠前）" prop="sortNum" required>
        <el-input v-model="form.sortNum" placeholder="请输入排序值（越小越靠前）" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaSysBannerWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        module: '',
        title: '',
        subTitle: '',
        url: '',
        operate: '',
        operateValue: '',
        status: '',
        sortNum: ''
      },
      // 验证规则
      rules: {
        module: [
          { required: true, message: '请选择所属模块' }
        ],
        title: [
          { required: true, message: '请输入标题' }
        ],
        url: [
          { required: true, message: '请上传图片' }
        ],
        operate: [
          { required: true, message: '请选择点击之后的操作' }
        ],
        operateValue: [
          { required: true, message: '请输入操作之后的值' }
        ],
        status: [
          { required: true, message: '请选择状态' }
        ],
        sortNum: [
          { required: true, message: '请输入排序值（越小越靠前）' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/admin/sysBanner',
      'field.id': 'id'
    })
  }
}
</script>
