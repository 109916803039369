<template>
  <GlobalWindow
      :title="title"
      :visible.sync="visible"
      :confirm-working="isWorking"
      @confirm="giveConfirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="券名称" prop="couponName">
        <el-input v-model="form.couponName" placeholder="请输入券名称" v-trim/>
      </el-form-item>
      <el-form-item label="券的类型" prop="useType" required>
        <rx-select pre-load v-model="form.useType" type="user_coupon_use_type"  ></rx-select>
      </el-form-item>
      <el-form-item v-if="form.useType == 2" label="景点" prop="shopIds">
        <rx-select v-model="form.shopIds" multiple pingying :limit="20" type="scenicList" placeholder="请选择景点，支持拼音搜索" ></rx-select>
      </el-form-item>
      <el-form-item v-if="form.useType == 4" label="商圈" prop="districtId">
        <rx-select v-model="form.districtId" pingying type="busList" placeholder="请选择商圈，支持拼音搜索" ></rx-select>
      </el-form-item>
      <el-form-item v-if="!!form.districtId" label="商户" prop="shopIds">
        <rx-select v-model="form.shopIds" multiple pingying :limit="20" type="merchantList" :pre-param="form.districtId" placeholder="请选择商户，支持拼音搜索" ></rx-select>
      </el-form-item>
      <el-form-item label="消费门槛" prop="consumeThreshold" required>
        <el-input v-model="form.consumeThreshold" placeholder="请输入消费门槛，无门槛请输入0" v-trim/>
      </el-form-item>
      <el-form-item label="优惠金额" prop="discount" required>
        <el-input v-model="form.discount" placeholder="请输入优惠金额" v-trim/>
      </el-form-item>
      <el-form-item label="券有效期" prop="validDate" required>
        <el-date-picker
            v-model="form.validDate"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            @change="dateChange"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="描述">
        <el-input v-model="form.description" placeholder="请输入描述" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaUserMemberWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        useType: null,
        userId: null,
        districtId: null,
        scenicId: null,
        shopIds: [],
        consumeThreshold: null,
        couponType: 2,
        discount: null,
        expirType: 1,
        expirValue: null,
        couponName: null,
        description: null,
        validDate: []
      },
      // 验证规则
      rules: {
        useType: [
          { required: true, message: '请选择券使用类型' }
        ],
        consumeThreshold: [
          { required: true, message: '请输入消费门槛' }
        ],
        discount: [
          { required: true, message: '请输入优惠金额' }
        ],
        validDate: [
          { required: true, message: '请选择券的有效期' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/admin/userMember',
      'field.id': 'id'
    })
  },
  methods: {
    open (title, target) {
      this.title = title
      this.visible = true
      this.$nextTick(() => {
        this.form.userId = target.id
      })
    },
    giveConfirm () {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        this.isWorking = true
        this.api.giveCoupon(this.form).then(() => {
          this.visible = false
          this.$tip.apiSuccess('赠送成功！')
          this.$emit('success')
          this.form = {
            useType: null,
            userId: null,
            districtId: null,
            shopIds: [],
            consumeThreshold: null,
            couponType: 2,
            discount: null,
            expirType: 1,
            expirValue: null,
            couponName: null,
            description: null,
            validDate: []
          }
        }).catch(e => {
          this.$tip.apiFailed(e)
        }).finally(() => {
          this.isWorking = false
        })
      })
    },
    dateChange (val) {
      if (!!val && val.length > 0) {
        this.form.expirValue = val.join('/')
      }
    }
  }
}
</script>
