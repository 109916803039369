import request from '@/utils/request'

// 查询列表
export function fetchList (data) {
  return request.post('/dulite/page', data, {
    trim: true
  })
}

// 统计
export function statistics () {
  return request.post('/dulite/statistics')
}

// 7天折线图
export function sevenEnterData () {
  return request.post('/dulite/sevenEnterData')
}
