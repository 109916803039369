<template>
  <div>
    <el-upload
        :accept="acceptType"
        class="avatar-uploader"
        action="action"
        :show-file-list="false"
        :disabled = "disabled"
        :http-request="httpRequest">
      <template v-if="fileType ==='img'">
        <img v-if="imgUrl && imgUrl != ''" :src="imgUrl" class="avatar" :style="{width: iconWidth+'px'}">
        <i v-else class="el-icon-plus avatar-uploader-icon" :style="{width: iconWidth+'px', lineHeight: iconWidth + 'px'}"></i>
      </template>
      <template v-if="fileType ==='file'">
        <span v-if="imgUrl && imgUrl != ''">{{fileName == null || fileName == '' ? '重新上传' : fileName}}</span>
        <i v-else class="el-icon-plus avatar-uploader-icon" :style="{width: iconWidth+'px', lineHeight: iconWidth + 'px'}"></i>
      </template>
      <template v-else>
        <i class="el-icon-plus avatar-uploader-icon" :style="{width: iconWidth+'px', lineHeight: iconWidth + 'px'}"></i>
      </template>
    </el-upload>
    <span v-if="fileType ==='file'">
      <a v-if="imgUrl && imgUrl != ''" :href="imgUrl" target="_blank">点击查看</a>
    </span>
  </div>
</template>
<script>
import request from '@/utils/request'

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    acceptType: {
      type: String,
      default: ''
    },
    fileType: {
      type: String,
      default: 'img'
    },
    // 1笔记/攻略，2用户头像，3用户背景，4投诉建议，5酒店评论，6banner
    type: {
      type: String,
      default: '1'
    },
    iconWidth: {
      type: Number,
      default: 200
    }
  },
  data () {
    return {
      imgUrl: this.value,
      fileReader: '',
      fileName: ''
    }
  },
  mounted () {
    if (!window.FileReader) {
      alert('此浏览器不支持图片上传，请跟换浏览器！')
    }
    this.fileReader = new FileReader()
  },
  methods: {
    httpRequest (options) {
      const formData = new FormData()
      const uploadFile = options.file
      formData.append('file', uploadFile)
      formData.append('type', this.type)
      this.loading.rxLoading()
      request.post('/tool/upload', formData).then(res => {
        this.imgUrl = res
        this.fileName = uploadFile.name
        this.$emit('input', this.imgUrl)
      }).finally(() => {
        this.loading.close()
      })
    }
  },
  watch: {
    value (n) {
      this.imgUrl = n
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  height: auto;
  text-align: center;
}
.avatar {
  height: auto;
  display: block;
}
</style>
