import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/cOrderHotel/page', data, {
    trim: true
  })
}

export function passRefund(data){
  return request.post("/cOrderHotel/passRefund", data)
}
