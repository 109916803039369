<template>
  <TableLayout :permissions="['cordermerchant:cordermerchant:query']">
    <SearchFormCollapse slot="search-form">
      <!-- 搜索表单 -->
      <el-form ref="searchForm" :model="searchForm" label-width="100px" inline>
        <el-form-item label="商圈" prop="districtId">
          <rx-select v-model="searchForm.districtId" pingying type="busList" placeholder="请选择商圈，支持拼音搜索" ></rx-select>
        </el-form-item>
        <el-form-item v-if="!!searchForm.districtId" label="商户" prop="merchantId">
          <rx-select v-model="searchForm.merchantId" pingying type="merchantList" :pre-param="searchForm.districtId" placeholder="请选择商户，支持拼音搜索" ></rx-select>
        </el-form-item>
        <el-form-item label="消费者手机号" prop="phone">
          <el-input v-model="searchForm.phone" placeholder="请输入消费者手机号" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="下单时间">
          <el-date-picker
              v-model="dataList"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              @change="dateChange"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单号" prop="platformNo">
          <el-input v-model="searchForm.platformNo" placeholder="请输入支付订单号" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="核销码" prop="consumeNo">
          <el-input v-model="searchForm.consumeNo" placeholder="请输入核销码" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="orderStatus">
          <rx-select v-model="searchForm.orderStatus" type="order_merchant_order_type" ></rx-select>
        </el-form-item>
        <el-form-item label="退款订单号" prop="platformRefundNo">
          <el-input v-model="searchForm.platformRefundNo" placeholder="请输入退款订单号" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
          <el-button type="primary" :loading="isWorking.export" @click="exportExcel">导出</el-button>
        </section>
      </el-form>
    </SearchFormCollapse>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar">
        <li><el-button type="primary" @click="billBatch">批量对账</el-button></li>
      </ul>
      <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          @selection-change="handleSelectionChange"
          stripe
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="districtName" label="商圈名称" min-width="180px"></el-table-column>
        <el-table-column prop="merchantName" label="商户名称" min-width="180px"></el-table-column>
        <el-table-column prop="phone" label="消费者手机号" min-width="120px"></el-table-column>
        <el-table-column prop="goodsName" label="商品名称" min-width="180px"></el-table-column>
        <el-table-column prop="thirdChannelNo" label="第三方平台的单号" min-width="180px"></el-table-column>
        <el-table-column prop="platformNo" label="平台订单号" min-width="200px"></el-table-column>
        <el-table-column prop="couponAmount" label="优惠券金额" min-width="100px"></el-table-column>
        <el-table-column prop="payPrice" label="实付金额" min-width="100px"></el-table-column>
        <el-table-column prop="copeAmount" label="平台应付商户金额" min-width="100px"></el-table-column>
        <el-table-column prop="deductionAmount" label="平台扣点" min-width="100px"></el-table-column>
        <el-table-column prop="actualAmount" label="平台实付金额" min-width="100px"></el-table-column>
        <el-table-column prop="consumeNo" label="核销码" min-width="220px"></el-table-column>
        <el-table-column prop="consumeTime" label="核销时间" min-width="180px"></el-table-column>
        <el-table-column prop="bill" label="对账状态" min-width="180px">
          <template v-slot="{row}">
            <span v-if="row.bill == 1">已对账</span>
            <span v-if="row.bill == 0">未对账</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="下单时间" min-width="180px"></el-table-column>
        <el-table-column prop="payTime" label="支付时间" min-width="180px"></el-table-column>
        <el-table-column prop="orderStatus" label="订单状态" min-width="100px">
          <template v-slot="{row}">
            {{getDictName('order_merchant_order_type', row.orderStatus)}}
          </template>
        </el-table-column>
        <el-table-column prop="buyNum" label="购买数量" min-width="100px"></el-table-column>
        <el-table-column prop="platformRefundNo" label="平台退款单号" min-width="100px"></el-table-column>
        <el-table-column prop="refundNo" label="第三方退款单号" min-width="180px"></el-table-column>
        <el-table-column
            label="操作"
            min-width="120"
            fixed="right"
        >
          <template slot-scope="{row}">
<!--         对账：    支付成功或者退款失败并且已经核销并且没对账。。。-->
            <el-button v-if="(row.orderStatus === 2 || row.orderStatus === 6) && row.consumeTime != null && row.bill === 0" type="text" @click="bill(row.id)">对账</el-button>
<!--         退款：    支付成功或者退款失败并且没有核销-->
            <el-button v-if="(row.orderStatus === 2 || row.orderStatus === 6) && row.consumeTime == null" type="text" @click="adminRefund(row.platformNo)">退款</el-button>
<!--         审核：     退款中-->
            <el-button v-if="row.orderStatus === 3" type="text" @click="$refs.refundAuditWindow.open(row.platformNo, row.id, 5)">审核</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
      >
      </pagination>
    </template>

    <!-- 审核-->
    <RefundAuditWindow ref="refundAuditWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import SearchFormCollapse from '@/components/common/SearchFormCollapse'
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import RefundAuditWindow from '@/components/admin/RefundAuditWindow'
export default {
  name: 'COrderMerchant',
  extends: BaseTable,
  components: { TableLayout, Pagination, RefundAuditWindow, SearchFormCollapse },
  data () {
    return {
      dataList: [],
      // 搜索
      searchForm: {
        districtId: null,
        merchantId: null,
        phone: null,
        platformNo: '',
        consumeNo: '',
        orderStatus: '',
        platformRefundNo: '',
        startDate: null,
        endDate: null,
      }
    }
  },
  created () {
    this.config({
      module: '商圈商户订单表',
      api: '/admin/cOrderMerchant',
      'field.id': 'id',
      'field.main': 'id',
      dictKeys: ['order_merchant_order_type']
    })
    this.search()
  },
  methods: {
    dateChange (val) {
      if (!!val && val.length > 0) {
        this.searchForm.startDate = val[0] + ' 00:00:00';
        this.searchForm.endDate = val[1] + ' 23:59:59';
      } else {
        this.searchForm.startDate = null;
        this.searchForm.endDate = null;
      }
    },
    adminRefund(orderNo){
      this.$dialog.confirmBox('是否确定退款？').then(() => {
        this.api.passRefund(orderNo).then(res => {
          this.$message.success('操作成功！')
          this.handlePageChange()
        })
      }).catch(() => {})
    },
    billBatch(){
      if(this.tableData.selectedRows.length == 0){
        this.$message.error("请选择需要对账的记录！");
        return;
      }
      // 符合的数据
      let ids = this.tableData.selectedRows.filter(row => (row.orderStatus === 2 || row.orderStatus === 6) && row.consumeTime != null && row.bill === 0).map(item=>item.id);
      if(ids.length == 0){
        this.$message.error("勾选的记录不能对账！");
        return;
      }
      this.$dialog.confirmBox('找到'+ ids.length +'条符合对账的数据，是否确定对账？').then(() => {
        this.api.bill({
          ids
        }).then(res => {
          this.$message.success('操作成功！')
          this.handlePageChange()
        })
      }).catch(() => {})
    },
    bill(id){
      this.$dialog.confirmBox('是否确定对账？').then(() => {
        this.api.bill({
          ids: [id]
        }).then(res => {
          this.$message.success('操作成功！')
          this.handlePageChange()
        })
      }).catch(() => {})
    }
  }
}
</script>
