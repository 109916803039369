<template>
  <div>
    <Profile :permissions="perms" >
      <div v-permissions="['sysconfig:operation:list']" v-if="params != null">
        <el-card class="margin_bottom_30" style="margin-top:5px;">
          <div slot='header' class="cardHeader">文创商城</div>
          <div class="cardBoxDivParentDiv">
            <div class="inline_block_div">
              <div class="cardBoxDivItemTitleDiv">包邮门槛：</div>
              <el-input v-model='params.mallFreeThreshold'></el-input>
              <div class="cardBoxDivItemTitleDiv">元。</div>
            </div>
            <div class="inline_block_div margin_left_50">
              <div class="cardBoxDivItemTitleDiv">不满包邮价格，需要支付运费：</div>
              <el-input v-model='params.postagePrice'></el-input>
              <div class="cardBoxDivItemTitleDiv">元。</div>
            </div>
          </div>
        </el-card>

        <el-card class="margin_bottom_30" style="margin-top:5px;">
          <div slot='header' class="cardHeader">攻略活动时间</div>
          <div class="cardBoxDivParentDiv">
            <div>
              <div class="inline_block_div">
                <div class="cardBoxDivItemTitleDiv">开始时间</div>
                <el-date-picker style="width: 50%" value-format="yyyy-MM-dd" v-model="params.noteStartTime" type="date" placeholder="选择开始日期"></el-date-picker>
              </div>
              <div class="inline_block_div margin_left_50">
                <div class="cardBoxDivItemTitleDiv">结束时间：</div>
                <el-date-picker style="width: 50%" value-format="yyyy-MM-dd" v-model="params.noteEndTime" type="date" placeholder="选择结束日期"></el-date-picker>
              </div>
            </div>
          </div>
        </el-card>

        <el-card class="margin_bottom_30" style="margin-top:5px;">
          <div slot='header' class="cardHeader">攻略审核模式</div>
          <div class="cardBoxDivParentDiv">
            <div>
              <div class="inline_block_div">
                <el-radio-group v-model="params.noteAuditFlag">
                  <el-radio label="1">自动通过</el-radio>
                  <el-radio label="2">人工审核</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
        </el-card>

      </div>
      <div class="center" v-permissions="['sysconfig:operation:update']">
        <el-button type="primary" @click.native='confirm'>保存设置</el-button>
      </div>
    </Profile>
  </div>
</template>
<script>
import Profile from '@/components/common/Profile'
import BasePage from '@/components/base/BasePage'
export default {
  name: 'SystemConfig',
  components: { Profile },
  extends: BasePage,
  data () {
    return {
      perms: ['sysconfig:operation:list', 'sysconfig:operation:update'],
      api: require('@/api/admin/sysconfig'),
      params: null
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    initData () {
      if (!this.containPermissions(this.perms)) {
        return
      }
      this.api.fetchList().then(res => {
        this.params = res
      })
    },
    confirm () {
      this.api.updateConfig(this.params).then(res => {
        this.$tip.success('保存成功！')
      }).catch(() => {
        this.$tip.error('保存失败！')
      })
    }
  }
}
</script>
<style type="less" scoped>

.cardBoxDivItemDiv{
  margin-top: 20px;
}

.inline_block_div, .cardBoxDivItemTitleDiv{
  display: inline-block;
}

.cardBoxDivParentDiv, .margin_left_50{
  margin-left: 50px;
}

>>> .el-radio{
  margin-left: 15px;
  margin-right: 0;
}

>>> .el-input{
  width: 75px;
  margin: 0 10px;
}

.center{
  padding: 10px 0;
  text-align: center;
}

.margin_bottom_30{
  font-size: 14px;
  margin-bottom: 5px;
}

>>>.el-form-item--small{
  width: 30%;
}

>>>.el-card__header .el-button--text{
  background-color: #fff;
  color: #505050;
  border-color:  #b5b5b5;
}

</style>
