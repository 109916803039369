import request from '@/utils/request'

// 查询
export function fetchList (data) {
    return request.post('/cPointConsume/page', data, {
        trim: true
    })
}

// 审核通过
export function pass (data) {
    return request.post('/cPointConsume/pass', data)
}