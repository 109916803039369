<template>
  <TableLayout :permissions="['pointsluckdrawprize:pointsluckdrawprize:query']">
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['pointsluckdrawprize:pointsluckdrawprize:update']">
        <li><el-button type="primary" @click="editDraw" icon="el-icon-edit-outline">编辑抽奖规则</el-button></li>
      </ul>
      <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
          @selection-change="handleSelectionChange"
      >
        <el-table-column prop="name" label="奖品名称" min-width="100px"></el-table-column>
        <el-table-column prop="goodLevel" label="奖品等级" min-width="100px"></el-table-column>
        <el-table-column prop="drawNum" label="中奖次数" min-width="100px"></el-table-column>
        <el-table-column prop="stock" label="奖品库存" min-width="100px"></el-table-column>
        <el-table-column prop="price" label="奖品价格" min-width="100px"></el-table-column>
        <el-table-column prop="baseNum" label="中奖概率基数" min-width="100px"></el-table-column>
        <el-table-column prop="probability" label="概率" min-width="100px"></el-table-column>
        <el-table-column
            v-if="containPermissions(['pointsluckdrawprize:pointsluckdrawprize:update'])"
            label="操作"
            min-width="120"
            fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaPointsLuckDrawPrizeWindow.open('奖品编辑', row)" icon="el-icon-edit" v-permissions="['pointsluckdrawprize:pointsluckdrawprize:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['pointsluckdrawprize:pointsluckdrawprize:update']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaPointsLuckDrawPrizeWindow ref="operaPointsLuckDrawPrizeWindow" @success="handlePageChange"/>

    <!-- 编辑抽奖规则 -->
    <el-dialog
        title="编辑抽奖规则"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="30%">
      <el-form ref="form" label-width="120px">
        <el-form-item label="抽奖模式">
          <el-radio-group v-model="drawInfo.drawType">
            <el-radio-button label="1"> 有效期模式 </el-radio-button>
            <el-radio-button label="2"> 库存模式 </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <br>
        <el-form-item label="抽奖有效期">
          <el-date-picker
              v-model="validDate"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveDrawTypeClick">确 定</el-button>
      </span>
    </el-dialog>

  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaPointsLuckDrawPrizeWindow from '@/components/admin/OperaPointsLuckDrawPrizeWindow'
export default {
  name: 'PointsLuckDrawPrize',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaPointsLuckDrawPrizeWindow },
  data () {
    return {
      // 搜索
      searchForm: {
      },
      dialogVisible: false,
      drawInfo: {
        queryFlag: false,
        drawType: null,
        startDate: null,
        endDate: null
      },
      validDate: []
    }
  },
  created () {
    this.config({
      module: '奖品',
      api: '/admin/pointsLuckDrawPrize',
      'field.id': 'id',
      'field.main': 'name'
    })
    this.search()
  },
  methods: {
    editDraw () {
      if (!this.drawInfo.queryFlag) {
        this.api.queryDrawType().then(res => {
          this.drawInfo.drawType = res.drawType
          this.drawInfo.startDate = res.startDate
          this.drawInfo.endDate = res.endDate
          this.drawInfo.queryFlag = true
          this.validDate = [res.startDate, res.endDate]
        })
      }
      this.dialogVisible = true
    },
    saveDrawTypeClick () {
      if (!this.drawInfo.drawType || !['1', '2'].includes(this.drawInfo.drawType)) {
        this.$message.error('请选择抽奖模式')
        return
      }

      if (!this.validDate || this.validDate.length != 2) {
        this.$message.error('请选择抽奖有效期')
        return
      }

      this.drawInfo.startDate = this.validDate[0]
      this.drawInfo.endDate = this.validDate[1]

      this.api.saveDrawType(this.drawInfo).then(res => {
        this.$message.success('保存成功')
        this.dialogVisible = false
      })
    }
  }
}
</script>
