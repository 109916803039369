import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/cMerchant/page', data, {
    trim: true
  })
}

// 创建
export function create (data) {
  return request.post('/cMerchant/create', data)
}

// 修改
export function updateById (data) {
  return request.post('/cMerchant/updateById', data)
}

// 修改
export function bindUser (phone, merchantId) {
  return request.post(`/cMerchant/bindUser/${phone}/${merchantId}`)
}

// 删除
export function deleteById (id) {
  return request.get(`/cMerchant/delete/${id}`)
}

// 批量删除
export function deleteByIdInBatch (ids) {
  return request.get('/cMerchant/delete/batch', {
    params: {
      ids
    }
  })
}

// 获取二维码
export function getMa (id) {
  return request.post('/cMerchant/ma/' + id)
}
