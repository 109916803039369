<template>
  <TableLayout :permissions="['corderscenic:corderscenic:query']">
    <SearchFormCollapse slot="search-form">
      <!-- 搜索表单 -->
      <el-form ref="searchForm" :model="searchForm" label-width="100px" inline>
        <el-form-item label="下单时间">
          <el-date-picker
              v-model="dataList"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              @change="dateChange"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="出游时间">
          <el-date-picker
              v-model="travelDataList"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              @change="travelDataChange"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="联系人姓名" prop="contactName">
          <el-input v-model="searchForm.contactName" placeholder="请输入订单联系人姓名" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="联系人手机号" prop="contactMobile">
          <el-input v-model="searchForm.contactMobile" placeholder="请输入订单联系人手机号" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="订单状态" prop="orderStatus">
          <rx-select v-model="searchForm.orderStatus" type="order_scenic_order_type"></rx-select>
        </el-form-item>
        <el-form-item label="平台订单号" prop="platformNo">
          <el-input v-model="searchForm.platformNo" placeholder="请输入平台订单号" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="景点名称" prop="scenicName">
          <el-input v-model="searchForm.scenicName" placeholder="请输入景点名称" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="用户id" prop="userId">
          <el-input v-model="searchForm.userId" placeholder="请输入用户id" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
        </section>
      </el-form>
    </SearchFormCollapse>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
      >
        <el-table-column prop="userId" label="用户id" min-width="180px"></el-table-column>
        <el-table-column prop="platformNo" label="平台订单号" min-width="260px"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="180px"></el-table-column>
        <el-table-column prop="orderStatus" label="订单状态" min-width="100px">
          <template v-slot="{row}">
            {{getDictName('order_scenic_order_type', row.orderStatus)}}
          </template>
        </el-table-column>
        <el-table-column prop="payTime" label="支付时间" min-width="180px"></el-table-column>
        <el-table-column prop="scenicId" label="第三方景点ID" min-width="100px"></el-table-column>
        <el-table-column prop="scenicName" label="景点名称" min-width="260px"></el-table-column>
        <el-table-column prop="fullAddress" label="景点地址" min-width="260px"></el-table-column>
        <el-table-column prop="productName" label="门票名称" min-width="260px"></el-table-column>
        <el-table-column prop="travelDate" label="出游日期" min-width="100px"></el-table-column>
        <el-table-column prop="quantity" label="购买数量" min-width="100px"></el-table-column>
        <el-table-column prop="createTime" label="下单时间" min-width="180px"></el-table-column>
        <el-table-column prop="discount" label="优惠券金额" min-width="100px"></el-table-column>
        <el-table-column prop="orderAmount" label="订单总金额" min-width="100px"></el-table-column>
        <el-table-column prop="contactName" label="联系人姓名" min-width="100px"></el-table-column>
        <el-table-column prop="contactMobile" label="联系人手机号" min-width="100px"></el-table-column>
        <el-table-column prop="orderRemark" label="下单备注" min-width="100px"></el-table-column>
        <el-table-column
            label="操作"
            min-width="120"
            fixed="right"
        >
          <template slot-scope="{row}">
            <el-button v-if="row.orderStatus === 10" type="text" @click="$refs.refundAuditWindow.open(row.platformNo, row.id, 3)">审核</el-button>
            <el-button v-if="row.orderStatus === 2" type="text" @click="enterVerify(row)">入园核验</el-button>
            <el-button v-if="row.specialFlag === 0" type="text" @click="special(row)">特殊标记</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 审核-->
    <RefundAuditWindow ref="refundAuditWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import SearchFormCollapse from '@/components/common/SearchFormCollapse'
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import RefundAuditWindow from '@/components/admin/RefundAuditWindow'
export default {
  name: 'COrderScenic',
  extends: BaseTable,
  components: { TableLayout, Pagination, RefundAuditWindow, SearchFormCollapse },
  data () {
    return {
      // 搜索
      dataList: [],
      travelDataList: [],
      searchForm: {
        userId: '',
        platformNo: '',
        orderStatus: '',
        scenicName: '',
        contactName: '',
        contactMobile: '',
        fullAddress: '',
        startDate: null,
        endDate: null,
        startTravelDate: null,
        endTravelDate: null
      }
    }
  },
  created () {
    this.config({
      module: '景点订单表',
      api: '/admin/cOrderScenic',
      'field.id': 'id',
      'field.main': 'id',
      dictKeys: ['order_scenic_order_type']
    })
    this.search()
  },
  methods: {
    dateChange (val) {
      if (!!val && val.length > 0) {
        this.searchForm.startDate = val[0] + ' 00:00:00';
        this.searchForm.endDate = val[1] + ' 23:59:59';
      } else {
        this.searchForm.startDate = null;
        this.searchForm.endDate = null;
      }
    },
    travelDataChange (val) {
      if (!!val && val.length > 0) {
        this.searchForm.startTravelDate = val[0];
        this.searchForm.endTravelDate = val[1];
      } else {
        this.searchForm.startTravelDate = null;
        this.searchForm.endTravelDate = null;
      }
    },
    enterVerify (row) {
      this.$dialog.confirmBoxHtml('是否确定核销：<br/>订单号：' + row.platformNo + '<br/>联系人：' + row.contactName + '<br/>联系电话：' + row.contactMobile).then(() => {
        this.api.enterVerify(row.id).then(res => {
          this.$message.success('操作成功！')
          this.handlePageChange()
        })
      }).catch(() => {})
    },
    special (row) {
      this.$dialog.confirmBox('是否确定标记订单['+row.platformNo+']').then(() => {
        this.api.special(row.id).then(res => {
          this.$message.success('操作成功！')
          this.handlePageChange()
        })
      }).catch(() => {})
    }
  }
}
</script>
