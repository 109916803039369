<template>
  <GlobalWindow
      :title="title"
      :visible.sync="visible"
      :confirm-working="isWorking"
      @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="商圈名称" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入商圈名称" v-trim/>
      </el-form-item>
      <el-form-item label="商圈的标签（英文逗号分割）" prop="label">
        <el-input v-model="form.label" placeholder="请输入商圈的标签" v-trim/>
      </el-form-item>
      <el-form-item label="商圈背景图" prop="backUrl">
        <rx-upload v-model="form.backUrl" type="9"></rx-upload>
      </el-form-item>
      <el-form-item label="经度" prop="longitude">
        <el-input v-model="form.longitude" placeholder="请输入经度" v-trim/>
      </el-form-item>
      <el-form-item label="纬度" prop="latitude">
        <el-input v-model="form.latitude" placeholder="请输入纬度" v-trim/>
      </el-form-item>
      <el-form-item label="详细地址" prop="fullAddress" required>
        <el-input v-model="form.fullAddress" placeholder="请输入详细地址" v-trim/>
      </el-form-item>
      <el-form-item label="商圈拥有的楼层（英文逗号分割）" prop="floor">
        <el-input v-model="form.floor" placeholder="请输入楼层(B1,1,2,3,4,5)" v-trim/>
      </el-form-item>
      <el-form-item label="营业开始时间" prop="openTime" required>
        <el-input v-model="form.openTime" placeholder="请输入商圈营业开始时间" v-trim/>
      </el-form-item>
      <el-form-item label="结束营业时间" prop="endTime" required>
        <el-input v-model="form.endTime" placeholder="请输入商圈结束营业时间" v-trim/>
      </el-form-item>
      <el-form-item label="状态" prop="status" required>
        <rx-select v-model="form.status" :data-list="[{code:1,label:'正常'},{code:0,label:'禁用'}]"  ></rx-select>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaCBusDistrictWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        name: '',
        label: '',
        backUrl: '',
        longitude: '',
        latitude: '',
        fullAddress: '',
        floor: '',
        openTime: '',
        endTime: '',
        tips: '',
        status: ''
      },
      // 验证规则
      rules: {
        name: [
          { required: true, message: '请输入商圈名称' }
        ],
        openTime: [
          { required: true, message: '请输入商圈营业开始时间' }
        ],
        endTime: [
          { required: true, message: '请输入商圈结束营业时间' }
        ],
        fullAddress: [
          { required: true, message: '请输入商圈详细地址' }
        ],
        status: [
          { required: true, message: '请选择状态' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/admin/cBusDistrict',
      'field.id': 'id'
    })
  }
}
</script>
