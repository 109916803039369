<template>
  <el-select :multiple-limit="limit" :collapse-tags="collapseTags" :multiple="multiple" filterable  v-if="pingying == true" :filter-method="PinyinMatchFun" clearable v-model="result" @change='changeSelect' @visible-change="getData" @clear='clear' :disabled="select_disabled" :size='size' :placeholder="placeholder">
    <el-option :label='item.label' :value='item.code' v-for='item in option' :key='item.code'>{{item.label}}</el-option>
  </el-select>
  <el-select v-else :multiple-limit="limit" :collapse-tags="collapseTags" :multiple="multiple"  filterable   clearable v-model="result" @change='changeSelect' @visible-change="getData" @clear='clear' :disabled="select_disabled" :size='size' :placeholder="placeholder">
    <el-option :label='item.label' :value='item.code' v-for='item in option' :key='item.code'>{{item.label}}</el-option>
  </el-select>
</template>
<script>

import PinyinMatch from 'pinyin-match'
import request from '@/utils/request'

export default {
  props: {
    limit: {
      type: Number,
      default: 0
    },
    size: {
      type: String,
      default: 'mini'
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    value: {
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    collapseTags: {
      type: Boolean,
      default: false
    },
    preLoad: { // 是否启用点击下拉框后加载
      type: Boolean,
      default: false
    },
    // 是否允许多选
    multiple: {
      type: Boolean,
      default: false
    },
    type: { // 用来区分select需要请求哪个数据
      type: String,
      default: null
    },
    // pingying     :false,//区分是否能使用拼音搜索
    pingying: {
      type: Boolean,
      default: true
    },
    dataList: {
      default: null
    },
    preParam: {
      default: null
    }
  },
  data () {
    return {
      result: this.value,
      option: null,
      select_disabled: this.disabled,
      options: []
    }
  },
  created () {
  },
  mounted () {
    if (this.preLoad) {
      this.getData()
    }
  },
  methods: {
    changeSelect (val) {
      this.$emit('input', this.result)
      this.$emit('change', this.result)
    },
    PinyinMatchFun (val) {
      if (val) {
        // 定义一个空数组用来存储过滤后的数据
        const result = []
        // 开始循环过滤内容
        this.options.forEach(i => {
          // 调用 PinyinMatch.match 方法进行拼音与汉字匹配
          const m = PinyinMatch.match(i.label, val)
          if (m) {
            // 匹配成功则push到result数组中
            result.push(i)
          }
        })
        // 将过滤后的数组重新赋给下拉列表数据
        this.option = result
      } else if (val === '') {
        // 如果输入框为空, 则将下拉列表数据还原
        this.option = this.options
      }
    },
    clear () {
      if (this.multiple) {
        this.result = []
      } else {
        this.result = null
      }
      this.option = this.options
      this.$emit('input', this.result)
    },
    getData () {
      if (this.option) {
        return
      }
      if (this.dataList != null && this.dataList.length > 0) {
        this.option = this.dataList
        this.options = this.dataList
        return
      }
      if (this.type === 'busList') {
        this.getBusList()
        return
      }
      if (this.type === 'merchantList') {
        this.getMerchantList()
        return
      }
      if (this.type === 'scenicList') {
        this.getScenicList()
        return
      }
      this.getDict(this.type)
    },
    getDict (key) {
      request.post('/tool/dict/' + key).then(res => {
        this.option = res
        this.options = res
      })
    },
    getBusList () {
      request.post('/cUser/busList').then(res => {
        let result
        if (!!res && res.length > 0) {
          result = res.map(item => {
            return {
              code: item.id,
              label: item.name
            }
          })
        } else {
          result = []
        }
        this.option = result
        this.options = result
      })
    },
    getScenicList () {
      request.post('/cAdminScenic/list').then(res => {
        let result
        if (!!res && res.length > 0) {
          result = res.map(item => {
            return {
              code: item.id,
              label: item.scenicName
            }
          })
        } else {
          result = []
        }
        this.option = result
        this.options = result
      })
    },
    getMerchantList () {
      let result
      if (this.preParam) {
        request.post('/cUser/merchantList/' + this.preParam).then(res => {
          if (!!res && res.length > 0) {
            result = res.map(item => {
              return {
                code: item.id,
                label: item.name
              }
            })
          } else {
            result = []
          }
          this.option = result
          this.options = result
        })
      } else {
        this.option = []
        this.options = []
      }
    }
  },
  watch: {
    preParam (n) {
      if (!!n && this.type == 'merchantList' && !!this.preParam) {
        this.result = null
        this.option = null
        this.options = null
        this.getMerchantList()
      }
    },
    dataList (n) {
      this.options = n
      this.option = n
    },
    value (n) {
      if (n == null) {
        if (this.multiple) {
          this.result = []
        } else {
          this.result = n
        }
      } else {
        this.result = n
      }
    },
    disabled (n) {
      this.select_disabled = n
    }
  }
}
</script>
