<template>
  <GlobalWindow
      :title="title"
      :visible.sync="visible"
      :confirm-working="isWorking"
      @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="景点名称" prop="scenicName" required>
        <el-input v-model="form.scenicName" placeholder="请输入景点名称" v-trim/>
      </el-form-item>
      <el-form-item label="所在省份" prop="provinceName" required>
        <el-input v-model="form.provinceName" placeholder="请输入所在省份" v-trim/>
      </el-form-item>
      <el-form-item label="所在城市" prop="cityName" required>
        <el-input v-model="form.cityName" placeholder="请输入所在城市" v-trim/>
      </el-form-item>
      <el-form-item label="景点地址" prop="scenicAddress" required>
        <el-input v-model="form.scenicAddress" placeholder="请输入景点地址" v-trim/>
      </el-form-item>
      <el-form-item label="经度" prop="longitude" required>
        <el-input v-model="form.longitude" placeholder="请输入经度" v-trim/>
      </el-form-item>
      <el-form-item label="纬度" prop="latitude" required>
        <el-input v-model="form.latitude" placeholder="请输入纬度" v-trim/>
      </el-form-item>
      <el-form-item label="图片" prop="image" required>
        <template v-if="!!form.image">
          <div class="itemImgBox">
            <div class="itemImg" v-for="item in form.image.split(',')" :key="item">
              <el-image class="itemElImg" fit="contain"
                        :src="item"
                        :preview-src-list="[item]">
              </el-image>
              <div class="itemImg_tool">
                <i class=" el-icon-delete" @click="deleteItemImg(item)"></i>
              </div>
            </div>
          </div>
        </template>
        <rx-upload  file-type="666" :icon-width="80" @input="imgUpload" type="12"></rx-upload>
      </el-form-item>
      <el-form-item label="星级，(0-5的数字)" prop="star" required>
        <el-input type="number" v-model="form.star" placeholder="请输入星级" v-trim/>
      </el-form-item>
      <el-form-item label="排序，越小越靠前" prop="sortNum" required>
        <el-input v-model="form.sortNum" placeholder="请输入排序，越小越靠前" v-trim type="number"/>
      </el-form-item>
      <el-form-item label="营业时间" prop="openTime" required>
        <el-input v-model="form.openTime" placeholder="请输入营业时间" v-trim/>
      </el-form-item>
      <el-form-item label="主题标签" prop="themeGroups">
        <el-input v-model="form.themeGroups" placeholder="请输入主题标签" v-trim/>
      </el-form-item>
      <el-form-item label="景点描述" prop="scenicDescription">
        <el-input v-model="form.scenicDescription" placeholder="请输入景点描述" v-trim/>
      </el-form-item>
      <el-form-item label="推荐信息" prop="recommend">
        <el-input v-model="form.recommend" placeholder="请输入推荐信息" v-trim/>
      </el-form-item>
      <el-form-item label="预订须知" prop="bookNotice">
        <el-input v-model="form.bookNotice" placeholder="请输入预订须知" v-trim/>
      </el-form-item>
      <el-form-item label="状态" prop="status" required>
        <rx-select v-model="form.status" :data-list="[{code:1,label:'正常'},{code:0,label:'禁用'}]"  ></rx-select>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaCAdminScenicWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        scenicName: '',
        provinceName: '',
        cityName: '',
        scenicAddress: '',
        longitude: '',
        latitude: '',
        image: null,
        star: '',
        sortNum: '',
        openTime: '',
        themeGroups: '',
        scenicDescription: '',
        recommend: '',
        bookNotice: '',
        status: '',
        deleted: '',
        createTime: '',
        createId: '',
        updateTime: '',
        updateId: ''
      },
      // 验证规则
      rules: {
        scenicName: [
          { required: true, message: '请输入景点名称' }
        ],
        provinceName: [
          { required: true, message: '请输入所在省份' }
        ],
        cityName: [
          { required: true, message: '请输入所在城市' }
        ],
        scenicAddress: [
          { required: true, message: '请输入景点地址' }
        ],
        longitude: [
          { required: true, message: '请输入经度' }
        ],
        latitude: [
          { required: true, message: '请输入纬度' }
        ],
        image: [
          { required: true, message: '请上传图片' }
        ],
        star: [
          { required: true, message: '请输入星级' }
        ],
        sortNum: [
          { required: true, message: '请输入排序，越小越靠前' }
        ],
        openTime: [
          { required: true, message: '请输入营业时间' }
        ],
        status: [
          { required: true, message: '请选择状态' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/admin/cAdminScenic',
      'field.id': 'id'
    })
  },
  methods: {
    imgUpload (imgUrl) {
      if (this.form.image) {
        this.form.image = this.form.image + ',' + imgUrl
      } else {
        this.form.image = imgUrl
      }
    },
    deleteItemImg(img){
      if(!this.form.image){
        return;
      }
      const imgList = this.form.image.split(",");
      const index = imgList.indexOf(img);
      if(index == -1){
        return;
      }
      imgList.splice(index, 1);
      this.form.image = imgList.join(",");
    }
  }
}
</script>
<style lang="scss">
.el-image-viewer__wrapper{
  z-index: 9999 !important;
}

.itemImgBox{
  display: flex;
  gap: 10px;
  .itemImg{
    width: 100px;
    height: 100px;
    position: relative;
    .itemElImg{
      width: 100px;
      height: 100px;
    }
    &:hover .itemImg_tool{
      display: flex;
    }
    .itemImg_tool{
      display: none;
      padding: 5px;
      background-color: rgba(0,0,0,0.5);
      position: absolute;
      color: #ffffff;
      top: 50%;
      left: 50%;
      font-size: 20px;
      transform: translate(-50%, -50%);
      cursor: pointer;
      justify-content: center;
      align-items: center;
      animation: 0.5s;
    }
  }
}

</style>
