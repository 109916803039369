<template>
  <div class="page_box">
    <div v-if="!!statisticsRecord">
      <div style="margin-bottom: 20px">
        <el-row :gutter="24">
          <el-col :span="8">
            <div>
              <el-statistic
                  group-separator=","
                  :precision="precision"
                  :value="statisticsRecord.todayEnter"
                  title="当日入园"
              ></el-statistic>
            </div>
          </el-col>

          <el-col :span="8">
            <div>
              <el-statistic
                  group-separator=","
                  :precision="precision"
                  :value="statisticsRecord.todayYuYue"
                  title="当日预约"
              ></el-statistic>
            </div>
          </el-col>

          <el-col :span="8">
            <div>
              <el-statistic
                  group-separator=","
                  :precision="precision"
                  :value="statisticsRecord.todayEarnings"
                  title="当日收益"
              ></el-statistic>
            </div>
          </el-col>
        </el-row>
      </div>

      <div>
        <el-row :gutter="24">
          <el-col :span="8">
            <div>
              <el-statistic
                  group-separator=","
                  :precision="precision"
                  :value="statisticsRecord.totalEnter"
                  title="累计入园"
              ></el-statistic>
            </div>
          </el-col>

          <el-col :span="8">
            <div>
              <el-statistic
                  group-separator=","
                  :precision="precision"
                  :value="statisticsRecord.totalYuYue"
                  title="累计预约"
              ></el-statistic>
            </div>
          </el-col>

          <el-col :span="8">
            <div>
              <el-statistic
                  group-separator=","
                  :precision="precision"
                  :value="statisticsRecord.totalEarnings"
                  title="累计收益"
              ></el-statistic>
            </div>
          </el-col>
        </el-row>
      </div>

    </div>

    <div style="margin-top: 15px; padding: 10px">
      <canvas id="myChart" width="300" height="300"></canvas>
    </div>

    <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <el-descriptions title="" v-for="item in list" :column="1" style="margin: 20px 0" border :key="item.platformNo">
        <el-descriptions-item label="订单号">{{item.platformNo}}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{item.contactMobile}}</el-descriptions-item>
        <el-descriptions-item label="入园时间">{{item.enterTime}}</el-descriptions-item>
        <el-descriptions-item label="购票时间">{{item.createTime}}</el-descriptions-item>
      </el-descriptions>
    </van-list>

  </div>
</template>

<script>
import { fetchList, statistics, sevenEnterData } from '@/api/admin/kongmiao'
import { List, Card } from 'vant'
import 'vant/lib/list/style'
import 'vant/lib/card/style'
import Chart from 'chart.js';

export default {
  name: 'kongmiao',
  components: {
    [List.name]: List,
    [Card.name]: Card
  },
  data () {
    return {
      precision: 0,
      list: [],
      loading: false,
      finished: false,
      currentPage: 1,
      pageNum: 20,
      statisticsRecord: null,
      sevenData: []
    }
  },
  created() {
    const now = new Date(); //获取今天日期
    now.setDate(now.getDate() - 6);
    let dateTemp;
    for (let i = 0; i < 7; i++) {
      dateTemp = (now.getMonth() + 1) + "/" + now.getDate();
      this.sevenData.push({
        date: now.getFullYear() + "-" + (now.getMonth() + 1).toString().padStart(2, '0') + "-" + now.getDate().toString().padStart(2, '0'),
        showTxt: dateTemp,
        num: 0
      });
      now.setDate(now.getDate() + 1);
    }
  },
  mounted(){
    statistics().then(res => {
      this.statisticsRecord = res
    })
    document.title = '孔庙景区数据统计';
    this.getLineData();
  },
  methods: {
    getLineData(){
      sevenEnterData().then(res=>{
        this.sevenData.forEach(item=>{
          let currentData = res[item.date];
          if(currentData){
            item.num = currentData.num;
          }
        })
        const chartData = {
          labels: this.sevenData.map(item => item.showTxt),
          datasets: [{
            label: '近7天孔庙入园人数',
            data: this.sevenData.map(item => item.num),
            fill: false,
            borderColor: 'rgb(75, 192, 192)', // 设置线的颜色
            backgroundColor: ['rgba(179, 0, 33, 0.5)'],// 设置点的填充色
            pointStyle: 'circle',     //设置点类型为圆点
            pointRadius: 6,    //设置圆点半径
            pointHoverRadius: 10, //设置鼠标移动上去后圆点半径
            tension: 0.1
          }]
        };
        const chartConfig = {
          type: 'line', // 设置图表类型
          data: chartData,
          options: {
            responsive: true,  // 设置图表为响应式
            interaction: {  // 设置每个点的交互
              intersect: false,
            },
            hover: {
              animationDuration: 0  // 防止鼠标移上去，数字闪烁
            },
            animation: {
              onComplete: function () {
                const chartInstance = this.chart,
                    ctx = chartInstance.ctx;
                ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                ctx.fillStyle = "black";
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';
                this.data.datasets.forEach(function (dataset, i) {
                  const meta = chartInstance.controller.getDatasetMeta(i);
                  meta.data.forEach(function (bar, index) {
                    const data = dataset.data[index];
                    ctx.fillText(data, bar._model.x-5, bar._model.y-10);
                  });
                });
              }
            }
          },
        };
        new Chart("myChart", chartConfig);
      })
    },
    onLoad () {
      fetchList({
        page: this.currentPage,
        capacity: this.pageNum,
        model: {}
      }).then(res => {
        const records = res.records
        if (records) {
          this.list.push(...records)
          if (records.length < this.pageNum) {
            this.finished = true
          } else {
            this.currentPage = this.currentPage + 1
          }
        } else {
          this.finished = true
        }
        this.loading = false
      })
    }
  }

}
</script>
<style scoped lang="scss">
.page_box{
  padding: 20px 10px;
}

::v-deep .el-descriptions--small {
  font-size: 14px !important;
}

</style>
