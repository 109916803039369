<template>
  <TableLayout :permissions="['cmerchant:cmerchant:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="商圈" prop="districtId">
        <rx-select v-model="searchForm.districtId" pingying type="busList" placeholder="请选择商圈，支持拼音搜索" ></rx-select>
      </el-form-item>
      <el-form-item label="商铺名称" prop="name">
        <el-input v-model="searchForm.name" placeholder="请输入商铺名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="详细地址" prop="fullAddress">
        <el-input v-model="searchForm.fullAddress" placeholder="请输入详细地址" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <rx-select v-model="searchForm.status" :data-list="[{code:1,label:'正常'},{code:0,label:'禁用'}]"  ></rx-select>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['cmerchant:cmerchant:create', 'cmerchant:cmerchant:delete']">
        <li><el-button type="primary" @click="$refs.operaCMerchantWindow.open('新建商圈商铺表')" icon="el-icon-plus" v-permissions="['cmerchant:cmerchant:create']">新建</el-button></li>
      </ul>
      <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
          @selection-change="handleSelectionChange"
      >
        <el-table-column prop="name" label="商铺名称" min-width="180px"></el-table-column>
        <el-table-column prop="districtName" label="所属商圈" min-width="180px"></el-table-column>
        <el-table-column prop="users" label="绑定用户" min-width="180px">
          <template slot-scope="{row}">
            <template v-if="!!row.users">
              <span>{{ row.users.join(",") }}</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="label" label="商铺的标签" min-width="180px"></el-table-column>
        <el-table-column prop="backUrl" label="商铺背景图" min-width="100px">
          <template slot-scope="{row}">
            <template v-if="!!row.backUrl">
              <el-image fit="contain"
                        style="width: 80px; height: 80px"
                        :src="row.backUrl"
                        :preview-src-list="[row.backUrl]">
              </el-image>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="longitude" label="经度" min-width="100px"></el-table-column>
        <el-table-column prop="latitude" label="纬度" min-width="100px"></el-table-column>
        <el-table-column prop="fullAddress" label="详细地址" min-width="180px"></el-table-column>
        <el-table-column prop="phone" label="联系电话" min-width="100px"></el-table-column>
        <el-table-column prop="openTime" label="营业时间" min-width="100px"></el-table-column>
        <el-table-column prop="endTime" label="结业时间" min-width="180px"></el-table-column>
        <el-table-column prop="floor" label="所属楼层" min-width="100px"></el-table-column>
        <el-table-column prop="sortNum" label="排序值" min-width="100px"></el-table-column>
        <el-table-column prop="status" label="状态" min-width="100px">
          <template slot-scope="{row}">
            <span v-if="row.status === 0">禁用</span>
            <span v-if="row.status === 1">正常</span>
          </template>
        </el-table-column>
        <el-table-column
            v-if="containPermissions(['cmerchant:cmerchant:update', 'cmerchant:cmerchant:delete'])"
            label="操作"
            min-width="240"
            fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="getMiniMa(row)" >获取二维码</el-button>
            <el-button type="text" @click="$refs.operaCMerchantWindow.open('编辑商圈商铺表', row)" icon="el-icon-edit" v-permissions="['cmerchant:cmerchant:update']">编辑</el-button>
            <el-button type="text" @click="getBindUserRow(row)" v-permissions="['cmerchant:cmerchant:update']">绑定用户</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['cmerchant:cmerchant:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaCMerchantWindow ref="operaCMerchantWindow" @success="handlePageChange"/>

    <!-- 二维码查看 -->
    <el-dialog
        title="商户小程序码"
        :visible.sync="dialogVisible"
        :append-to-body="true"
        width="50%">
      <template v-if="!!selectRow">
        <el-form>
          <el-form-item label="当前商铺">
            {{ selectRow.name }}
          </el-form-item>
          <el-form-item label="小程序码">
            <img v-if="!!miniMaUrl" :src="miniMaUrl" style="width: 360px; height: auto">
          </el-form-item>
        </el-form>
      </template>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 绑定用户 -->
    <el-dialog
        title="绑定用户"
        :visible.sync="bingUserDialogVisible"
        :append-to-body="true"
        width="50%">
      <template v-if="!!bingUserRow">
        <el-form>
          <el-form-item label="当前商铺">
            {{ bingUserRow.name }}
          </el-form-item>
          <el-form-item label="用户手机号" required>
            <el-input v-model="bindUserPhone"></el-input>
          </el-form-item>
        </el-form>
      </template>

      <span slot="footer" class="dialog-footer">
        <el-button @click="bingUserDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveBindUser">确 定</el-button>
      </span>
    </el-dialog>

  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaCMerchantWindow from '@/components/admin/OperaCMerchantWindow'
export default {
  name: 'CMerchant',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaCMerchantWindow },
  data () {
    return {
      dialogVisible: false,
      bingUserDialogVisible: false,
      miniMaUrl: null,
      selectRow: null,
      bingUserRow: null,
      bindUserPhone: null,
      // 搜索
      searchForm: {
        districtId: '',
        name: '',
        fullAddress: '',
        status: ''
      }
    }
  },
  created () {
    this.config({
      module: '商圈商铺表',
      api: '/admin/cMerchant',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  },
  methods: {
    saveBindUser () {
      if (this.bindUserPhone == null || this.bindUserPhone == undefined || this.bindUserPhone == '') {
        this.$message.error('请填写手机号！')
        return
      }
      const loading = this.$loading({
        lock: true,
        text: '操作中，请稍后！',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.api.bindUser(this.bindUserPhone, this.bingUserRow.id).then(() => {
        this.handlePageChange()
        this.$message.success('绑定成功！')
        this.bingUserDialogVisible = false
        this.bindUserPhone = null
        this.bingUserRow = null
      }).finally(() => {
        loading.close()
      })
    },
    getBindUserRow (row) {
      this.bingUserDialogVisible = true
      this.bingUserRow = row
    },
    getMiniMa (row) {
      this.selectRow = row
      const loading = this.$loading({
        lock: true,
        text: '操作中，请稍后！',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.api.getMa(row.id).then(res => {
        this.miniMaUrl = res
        this.dialogVisible = true
      }).finally(() => {
        loading.close()
      })
    }
  }
}
</script>
