<template>
  <TableLayout :permissions="['cregistercoupon:cregistercoupon:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="使用场景" prop="useType">
        <rx-select v-model="searchForm.useType" type="user_coupon_use_type"></rx-select>
      </el-form-item>
      <el-form-item label="折扣类型" prop="randomType">
        <rx-select v-model="searchForm.randomType" type="user_coupon_dis_type"></rx-select>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <rx-select v-model="searchForm.randomType" :data-list="[{code:1,label:'启用'},{code:0,label:'停用'}]"></rx-select>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['cregistercoupon:cregistercoupon:create', 'cregistercoupon:cregistercoupon:delete']">
        <li><el-button type="primary" @click="$refs.operaCRegisterCouponWindow.open('新建注册赠送优惠券表')" icon="el-icon-plus" v-permissions="['cregistercoupon:cregistercoupon:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['cregistercoupon:cregistercoupon:delete']">删除</el-button></li>
      </ul>
      <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="useType" label="使用场景" min-width="100px">
          <template v-slot="{row}">
            {{getDictName('user_coupon_use_type', row.useType)}}
          </template>
        </el-table-column>
        <el-table-column prop="receiveExpirNum" label="过期天数" min-width="100px"></el-table-column>
        <el-table-column prop="randomType" label="折扣类型" min-width="100px">
          <template v-slot="{row}">
            {{getDictName('user_coupon_dis_type', row.randomType)}}
          </template>
        </el-table-column>
        <el-table-column prop="randomDiscount" label="折扣/金额" min-width="100px"></el-table-column>
        <el-table-column prop="couponNum" label="券的张数" min-width="100px"></el-table-column>
        <el-table-column prop="couponName" label="券名称" min-width="100px"></el-table-column>
        <el-table-column prop="consumeThreshold" label="消费门槛" min-width="100px"></el-table-column>
        <el-table-column prop="description" label="描述" min-width="100px"></el-table-column>
        <el-table-column prop="status" label="状态" min-width="100px">
          <template v-slot="{row}">
            <span v-if="row.status === 1">启用</span>
            <span v-if="row.status === 0">停用</span>
          </template>
        </el-table-column>
        <el-table-column
            v-if="containPermissions(['cregistercoupon:cregistercoupon:update', 'cregistercoupon:cregistercoupon:delete'])"
            label="操作"
            min-width="120"
            fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaCRegisterCouponWindow.open('编辑注册赠送优惠券表', row)" icon="el-icon-edit" v-permissions="['cregistercoupon:cregistercoupon:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['cregistercoupon:cregistercoupon:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaCRegisterCouponWindow ref="operaCRegisterCouponWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaCRegisterCouponWindow from '@/components/admin/OperaCRegisterCouponWindow'
export default {
  name: 'CRegisterCoupon',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaCRegisterCouponWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        useType: '',
        randomType: '',
        status: ''
      }
    }
  },
  created () {
    this.config({
      module: '注册赠送优惠券表',
      api: '/admin/cRegisterCoupon',
      'field.id': 'id',
      'field.main': 'id',
      dictKeys: ["user_coupon_use_type", "user_coupon_dis_type"]
    })
    this.search()
  }
}
</script>
