import axios from 'axios'
import Cookies from 'js-cookie'
import pkg from '../../../package'
import { trim } from '@/utils/util'
import extendsMethods from './extends.methods'
import { Message } from 'element-ui'

// 默认配置
axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8'
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_PREFIX,
  // 请求超时时间
  timeout: 60000
})

// 新建请求拦截器
axiosInstance.interceptors.request.use(config => {
  // 参数去空格
  if (config.trim === true) {
    if (config.data != null) {
      config.data = trim(config.data)
    }
    if (config.params != null) {
      config.params = trim(config.params)
    }
  }
  // 导出处理
  if (config.download === true) {
    config.responseType = 'blob'
  }
  // 设置操作平台
  config.headers['eva-platform'] = `pc-${pkg.version}`
  // 设置认证头
  const authToken = Cookies.get('eva-auth-token')
  if (authToken != null) {
    config.headers['eva-auth-token'] = authToken
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

// 新建响应拦截器
axiosInstance.interceptors.response.use((response) => {
  // 请求失败
  if (response.status !== 200) {
    return Promise.reject(new Error('服务器繁忙，请稍后再试'))
  }
  // 下载接口处理
  if (response.headers['eva-opera-type'] === 'download') {
    return Promise.resolve(response)
  }
  // 未登录
  if (response.data.code === 401) {
    if (response.config.autoLogin !== false) {
      window.location.href = process.env.VUE_APP_ROUTER_MODE === 'history' ? '/#/login' : '/login'
    }
    return Promise.reject(response.data)
  }
  // 业务失败
  if (!response.data.success) {
    Message.error(response.data.message)
    return Promise.reject(response.data)
  }
  return response.data.data
}, function (error) {
  console.log('error', error)
  if (error.code == null) {
    return Promise.reject(new Error('服务器繁忙，请稍后再试'))
  }
  if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
    return Promise.reject(new Error('服务器响应超时，请稍后再试'))
  }
  return Promise.reject(error)
})

// 添加扩展方法
for (const key in extendsMethods) {
  axiosInstance[key] = extendsMethods[key]
}

export default axiosInstance
