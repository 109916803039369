<template>
  <TableLayout :permissions="['csuggestion:csuggestion:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="用户id" prop="userId">
        <el-input v-model="searchForm.userId" placeholder="请输入用户id" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <rx-select v-model="searchForm.type" type="suggestion_type"></rx-select>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
      >
        <el-table-column prop="userId" label="用户id" min-width="100px"></el-table-column>
        <el-table-column prop="userPhone" label="用户手机号" min-width="100px"></el-table-column>
        <el-table-column prop="type" label="类型" min-width="100px">
          <template v-slot="{row}">
            {{getDictName('suggestion_type', row.type)}}
          </template>
        </el-table-column>
        <el-table-column prop="content" label="内容" min-width="100px">
          <template v-slot="{row}">
            <column-detail :limit="100" :content="row.content" :analyse="false"></column-detail>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="提交时间" min-width="100px"></el-table-column>
        <el-table-column prop="content" label="附件" min-width="100px">
          <template v-slot="{row}">
            <el-button @click="lookFile(row)" v-if="!!row.fileList && row.fileList.length > 0">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
      >
      </pagination>
    </template>

    <!-- 附件查看 -->
    <el-dialog
        title="查看附件"
        :visible.sync="dialogVisible"
        :append-to-body="true"
        width="50%">
      <div v-if="!!currentImgList && currentImgList.length > 0">
        <h2>图片</h2>
        <div class="showImgClass">
          <div class="showImgItemClass" v-for="item in currentImgList" :key="item">
            <el-image fit="contain"
                      :src="item"
                      :z-index="9999"
                      :preview-src-list="[item]">
            </el-image>
          </div>
        </div>
      </div>
      <br/>
      <br/>
      <div v-if="!!currentVideoList && currentVideoList.length > 0">
        <h2>视频</h2>
        <template v-for="item in currentVideoList">
          <video class="showVideoItem" :src="item" controls></video>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import ColumnDetail from '@/components/common/ColumnDetail'
export default {
  name: 'CSuggestion',
  extends: BaseTable,
  components: { TableLayout, Pagination, ColumnDetail },
  data () {
    return {
      // 搜索
      searchForm: {
        userId: '',
        type: ''
      },
      dialogVisible: false,
      currentImgList: [],
      currentVideoList: []
    }
  },
  created () {
    this.config({
      module: '投诉建议表',
      api: '/admin/cSuggestion',
      'field.id': 'id',
      'field.main': 'id',
      dictKeys: ['suggestion_type']
    })
    this.search()
  },
  methods: {
    lookFile (item) {
      this.currentVideoList = []
      this.currentImgList = []
      if (item.fileList) {
        this.dialogVisible = true
        item.fileList.map(item => {
          if (item.type == 1) {
            this.currentImgList.push(item.url)
          } else {
            this.currentVideoList.push(item.url)
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.showImgClass{
  display: flex;
  gap: 20px;
  flex-flow:row wrap
}

.showImgItemClass{
  flex: 0 0 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.showVideoItem{
  width: 300px;
  height: 300px;
  margin: 20px;
}

</style>
