<template>
  <TableLayout :permissions="['cbusdistrict:cbusdistrict:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="商圈名称" prop="name">
        <el-input v-model="searchForm.name" placeholder="请输入商圈名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="详细地址" prop="fullAddress">
        <el-input v-model="searchForm.fullAddress" placeholder="请输入详细地址" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <rx-select v-model="searchForm.status" :data-list="[{code:1,label:'正常'},{code:0,label:'禁用'}]"  ></rx-select>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['cbusdistrict:cbusdistrict:create', 'cbusdistrict:cbusdistrict:delete']">
        <li><el-button type="primary" @click="$refs.operaCBusDistrictWindow.open('新建商圈表')" icon="el-icon-plus" v-permissions="['cbusdistrict:cbusdistrict:create']">新建</el-button></li>
      </ul>
      <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
          @selection-change="handleSelectionChange"
      >
        <el-table-column prop="name" label="商圈名称" min-width="180px"></el-table-column>
        <el-table-column prop="label" label="商圈的标签" min-width="180px"></el-table-column>
        <el-table-column prop="backUrl" label="商圈背景图" min-width="100px">
          <template slot-scope="{row}">
            <template v-if="!!row.backUrl">
              <el-image fit="contain"
                        style="width: 80px; height: 80px"
                        :src="row.backUrl"
                        :preview-src-list="[row.backUrl]">
              </el-image>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="longitude" label="经度" min-width="100px"></el-table-column>
        <el-table-column prop="latitude" label="纬度" min-width="100px"></el-table-column>
        <el-table-column prop="fullAddress" label="详细地址" min-width="100px"></el-table-column>
        <el-table-column prop="floor" label="楼层" min-width="100px"></el-table-column>
        <el-table-column prop="openTime" label="营业时间" min-width="100px"></el-table-column>
        <el-table-column prop="endTime" label="结业时间" min-width="100px"></el-table-column>
        <el-table-column prop="status" label="状态" min-width="100px">
          <template slot-scope="{row}">
            <span v-if="row.status === 0">禁用</span>
            <span v-if="row.status === 1">正常</span>
          </template>
        </el-table-column>
        <el-table-column
            v-if="containPermissions(['cbusdistrict:cbusdistrict:update', 'cbusdistrict:cbusdistrict:delete'])"
            label="操作"
            min-width="120"
            fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaCBusDistrictWindow.open('编辑商圈表', row)" icon="el-icon-edit" v-permissions="['cbusdistrict:cbusdistrict:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['cbusdistrict:cbusdistrict:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaCBusDistrictWindow ref="operaCBusDistrictWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaCBusDistrictWindow from '@/components/admin/OperaCBusDistrictWindow'
export default {
  name: 'CBusDistrict',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaCBusDistrictWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        name: '',
        fullAddress: '',
        status: ''
      }
    }
  },
  created () {
    this.config({
      module: '商圈表',
      api: '/admin/cBusDistrict',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
