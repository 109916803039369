<template>
  <div>
    <el-dialog
        title="退款审核"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="30%">
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-form-item label="订单号" prop="orderNo">
          {{ form.orderNo }}
        </el-form-item>
        <el-form-item label="状态" prop="status" required>
          <el-radio-group v-model="form.status">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="0">拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="拒绝原因" prop="refundFailReason" :rules="[{ required: form.status===0, message: '请输入拒绝原因'}]">
          <el-input type="textarea" v-model="form.refundFailReason" maxlength="200" show-word-limit :rows="7"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="passAudit">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import { passRefund } from '../../api/admin/cOrderHotel'
export default {
  name: 'RefundAuditWindow',
  data () {
    return {
      dialogVisible: false,
      form: {
        orderNo: null,
        id: null,
        status: null,
        refundFailReason: null,
        type: null
      },
      rules: {
        status: [
          { required: true, message: '请选择状态' }
        ]
      }
    }
  },
  methods: {
    /**
     *
     * @param orderNo   订单号
     * @param id        id
     * @param type      类型(1酒店，2商城，3景点门票，4行程，5商户)
     */
    open (orderNo, id, type) {
      this.dialogVisible = true
      this.form.orderNo = orderNo
      this.form.id = id
      this.form.type = type
    },
    passAudit () {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        this.loading.rxLoading()
        passRefund(this.form).then(res => {
          this.dialogVisible = false
          this.form = {
            orderNo: null,
            id: null,
            status: null,
            refundFailReason: null,
            type: null
          },
          this.$message.success('操作成功！')
          this.$emit('success')
        }).catch(e => {
          this.$message.error('审核失败，请联系相关人员')
        }).finally(() => {
          this.loading.close()
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
