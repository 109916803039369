import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/pointsLuckDrawPrize/page', data, {
    trim: true
  })
}

// 修改
export function updateById (data) {
  return request.post('/pointsLuckDrawPrize/updateById', data)
}

// 修改
export function deleteById (id) {
  return request.post('/pointsLuckDrawPrize/delete/' + id)
}

// 查询抽奖配置
export function queryDrawType () {
  return request.post('/pointsLuckDrawPrize/queryDrawType')
}

// 保存抽奖配置
export function saveDrawType (data) {
  return request.post('/pointsLuckDrawPrize/saveDrawType', data)
}
