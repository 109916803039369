import Vue from 'vue'

class Loading extends Vue {
  rxLoading () {
    this.loading = this.$loading({
      lock: true,
      text: '数据处理中，请稍后...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
  }

  /* 封装loading */
  loading () {
    this.loading = this.$loading({
      lock: true,
      text: '数据处理中，请稍后...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    return this.loading
  }

  close () {
    if (this.loading) {
      this.loading.close()
    }
  }
}

export default new Loading()
