<template>
  <TableLayout :permissions="['cuser:cuser:query']">
    <!-- 搜索表单 -->
    <div slot="search-form">
      <el-form ref="searchForm" :model="searchForm" label-width="100px" inline>
        <el-form-item label="用户ID" prop="loginName">
          <el-input v-model="searchForm.id" placeholder="请输入用户ID" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="loginName">
          <el-input v-model="searchForm.loginName" placeholder="请输入手机号" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <rx-select v-model="searchForm.sex" :data-list="[{code:1,label:'男'},{code:2,label:'女'}]"></rx-select>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <rx-select v-model="searchForm.status" :data-list="[{code:1,label:'启用'},{code:0,label:'禁用'}]"  ></rx-select>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
        </section>
      </el-form>
    </div>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
      >
        <el-table-column prop="id" label="用户ID" width="180px"></el-table-column>
        <el-table-column prop="loginName" label="手机号" width="100px"></el-table-column>
        <el-table-column prop="logo" label="用户头像" width="100px">
          <template slot-scope="{row}">
            <template v-if="!!row.logo">
              <el-image fit="contain"
                  style="width: 80px; height: 80px"
                  :src="row.logo"
                  :preview-src-list="[row.logo]">
              </el-image>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="nickName" label="用户昵称" width="100px"></el-table-column>
        <el-table-column prop="sex" label="性别" width="70px">
          <template slot-scope="{row}">
            <span v-if="row.sex == 1">男</span>
            <span v-if="row.sex == 2">女</span>
          </template>
        </el-table-column>
        <el-table-column prop="birthday" label="生日" width="120px"></el-table-column>
        <el-table-column prop="aboutMe" label="个人介绍" min-width="100px"></el-table-column>
        <el-table-column prop="status" label="状态" min-width="100px">
          <template slot-scope="{row}">
            <span v-if="row.status == 1">启用</span>
            <span v-if="row.status == 0">禁用</span>
          </template>
        </el-table-column>
        <el-table-column prop="inviteCode" label="用户邀请码" width="100px"></el-table-column>
        <el-table-column prop="recommendCode" label="推荐人邀请码" width="100px"></el-table-column>
        <el-table-column prop="lastLoginTime" label="最后一次登录时间" width="180px"></el-table-column>
        <el-table-column prop="createTime" label="注册时间" width="180px"></el-table-column>
        <el-table-column label="操作" width="200px">
          <template slot-scope="{row}">
            <el-button @click="enableOrUser(row, 1)" v-if="row.status == 0" type="text">启用</el-button>
            <el-button @click="enableOrUser(row, 0)" v-if="row.status == 1" type="text">禁用</el-button>
            <el-button @click="$refs.operaUserMemberWindow.open('给用户【'+row.loginName+'】赠送优惠券', row)" v-if="row.status == 1" type="text">赠送优惠券</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaUserMemberWindow ref="operaUserMemberWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaUserMemberWindow from '@/components/admin/OperaUserMemberWindow'

export default {
  name: 'CUser',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaUserMemberWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        id: null,
        loginName: '',
        sex: '',
        status: '',
        inviteCode: '',
        recommendCode: '',
        recommendType: ''
      }
    }
  },
  created () {
    this.config({
      module: '用户表',
      api: '/admin/userMember',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  },
  methods: {
    enableOrUser (row, status) {
      this.$dialog.confirmBox('确定' + (status === 1 ? '启用' : '禁用') + '【' + row.loginName + '】吗？').then(res => {
        this.api.enableUser(row.id, status).then(res => {
          this.$message.success('操作成功！')
          this.handlePageChange()
        })
      }).catch(res => {

      })
    }
  }
}
</script>
