<template>
  <TableLayout :permissions="['cgoods:cgoods:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="商品名称" prop="goodsName">
        <el-input v-model="searchForm.goodsName" placeholder="请输入商品名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <rx-select v-model="searchForm.status" :data-list="[{code:1,label:'上架'},{code:0,label:'下架'}]"  ></rx-select>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['cgoods:cgoods:create', 'cgoods:cgoods:delete']">
        <li><el-button type="primary" @click="$refs.operaCGoodsWindow.open('新建商城商品表')" icon="el-icon-plus" v-permissions="['cgoods:cgoods:create']">新建</el-button></li>
      </ul>
      <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
          @selection-change="handleSelectionChange"
      >
        <el-table-column prop="goodsName" label="商品名称" min-width="100px"></el-table-column>
        <el-table-column prop="goodsPrice" label="商品售价(元)" min-width="100px"></el-table-column>
<!--        <el-table-column prop="sn" label="商品编码" min-width="100px"></el-table-column>-->
        <el-table-column prop="goodsSourceImg" label="商品封面图" width="100px">
          <template slot-scope="{row}">
            <template v-if="!!row.goodsSourceImg">
              <el-image fit="contain"
                        style="width: 80px; height: 80px"
                        :src="row.goodsSourceImg.split(',')[0]"
                        :preview-src-list="row.goodsSourceImg.split(',')">
              </el-image>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" min-width="100px">
          <template slot-scope="{row}">
            <span v-if="row.status === 1">上架</span>
            <span v-if="row.status === 2">下架</span>
          </template>
        </el-table-column>
        <el-table-column prop="quantity" label="商品库存" min-width="100px"></el-table-column>
        <el-table-column prop="weight" label="商品重量（g）" min-width="100px"></el-table-column>
        <el-table-column prop="recommend" label="是否为推荐商品" min-width="100px">
          <template slot-scope="{row}">
            <span v-if="row.recommend === 1">是</span>
            <span v-if="row.recommend === 0">否</span>
          </template>
        </el-table-column>
        <el-table-column prop="freight" label="商品运费" min-width="100px"></el-table-column>
        <el-table-column
            v-if="containPermissions(['cgoods:cgoods:update', 'cgoods:cgoods:delete'])"
            label="操作"
            min-width="120"
            fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaCGoodsWindow.open('编辑商城商品表', row)" icon="el-icon-edit" v-permissions="['cgoods:cgoods:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['cgoods:cgoods:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaCGoodsWindow ref="operaCGoodsWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaCGoodsWindow from '@/components/admin/OperaCGoodsWindow'
export default {
  name: 'CGoods',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaCGoodsWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        goodsName: '',
        status: ''
      }
    }
  },
  created () {
    this.config({
      module: '商城商品表',
      api: '/admin/cGoods',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
