import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/cOrderScenic/page', data, {
    trim: true
  })
}

// 入园核销
export function enterVerify (id) {
  return request.post('/cOrderScenic/enterVerify/' + id)
}

// 特殊订单标记
export function special (id) {
  return request.post('/cOrderScenic/special/' + id)
}
