<template>
  <GlobalWindow
      :title="title"
      :visible.sync="visible"
      :confirm-working="isWorking"
      @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="商品名称" prop="goodsName" required>
        <el-input v-model="form.goodsName" placeholder="请输入商品名称" v-trim/>
      </el-form-item>
      <el-form-item label="商品售价" prop="goodsPrice" required>
        <el-input v-model="form.goodsPrice" placeholder="请输入商品售价" v-trim/>
      </el-form-item>
      <el-form-item label="商品封面图" prop="goodsSourceImg" required>
        <template v-if="!!form.goodsSourceImg">
          <el-image v-for="item in form.goodsSourceImg.split(',')" :key="item" fit="contain"
                    style="width: 80px; height: 80px"
                    :src="item"
                    :preview-src-list="[item]">
          </el-image>
        </template>
        <rx-upload  file-type="666" :icon-width="80" @input="imgUpload"></rx-upload>
      </el-form-item>
      <el-form-item label="商品介绍" prop="intro">
        <el-input v-model="form.intro" placeholder="请输入商品介绍" v-trim/>
      </el-form-item>
      <el-form-item label="商品详情" prop="content" required>
        <rx-editor v-model="form.content"></rx-editor>
      </el-form-item>
      <el-form-item label="状态" prop="status" required>
        <rx-select v-model="form.status" :data-list="[{code:1,label:'上架'},{code:0,label:'下架'}]"  ></rx-select>
      </el-form-item>
      <el-form-item label="商品库存" prop="quantity" required>
        <el-input v-model="form.quantity" placeholder="请输入商品库存" v-trim/>
      </el-form-item>
      <el-form-item label="商品重量（g）" prop="weight" required>
        <el-input v-model="form.weight" placeholder="请输入商品重量（g）" v-trim/>
      </el-form-item>
      <el-form-item label="是否为推荐商品" prop="recommend" required>
        <rx-select v-model="form.recommend" :data-list="[{code:1,label:'是'},{code:0,label:'否'}]"  ></rx-select>
      </el-form-item>
      <el-form-item label="运费金额" prop="freight">
        <el-input v-model="form.freight" placeholder="请输入运费金额" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaCGoodsWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        goodsName: '',
        goodsPrice: '',
        goodsVideo: '',
        goodsSourceImg: '',
        intro: '',
        content: '',
        status: '',
        quantity: '',
        weight: '',
        recommend: '',
        freight: ''
      },
      // 验证规则
      rules: {
        goodsName: [
          { required: true, message: '请输入商品名称' }
        ],
        goodsPrice: [
          { required: true, message: '请输入商品售价' }
        ],
        content: [
          { required: true, message: '请输入商品详情' }
        ],
        status: [
          { required: true, message: '请选择状态' }
        ],
        quantity: [
          { required: true, message: '请输入商品库存' }
        ],
        goodsSourceImg: [
          { required: true, message: '请上传商品封面图' }
        ],
        weight: [
          { required: true, message: '请输入商品重量（g）' }
        ],
        recommend: [
          { required: true, message: '请选择是否为推荐商品' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/admin/cGoods',
      'field.id': 'id'
    })
  },
  methods: {
    imgUpload (imgUrl) {
      if (this.form.goodsSourceImg) {
        this.form.goodsSourceImg = this.form.goodsSourceImg + ',' + imgUrl
      } else {
        this.form.goodsSourceImg = imgUrl
      }
    }
  }
}
</script>
