<template>
  <TableLayout :permissions="['cadminscenicticket:cadminscenicticket:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="景点" prop="productName">
        <rx-select v-model="searchForm.scenicId" type="scenicList"></rx-select>
      </el-form-item>
      <el-form-item label="门票名称" prop="productName">
        <el-input v-model="searchForm.productName" placeholder="请输入门票名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <rx-select v-model="searchForm.status" :data-list="[{code:1,label:'正常'},{code:0,label:'禁用'}]"  ></rx-select>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['cadminscenicticket:cadminscenicticket:create', 'cadminscenicticket:cadminscenicticket:delete']">
        <li><el-button type="primary" @click="$refs.operaCAdminScenicTicketWindow.open('新建景点门票')" icon="el-icon-plus" v-permissions="['cadminscenicticket:cadminscenicticket:create']">新建</el-button></li>
      </ul>
      <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
          @selection-change="handleSelectionChange"
      >
        <el-table-column prop="scenicName" label="所属景点" min-width="100px"></el-table-column>
        <el-table-column prop="productName" label="门票名称" min-width="100px"></el-table-column>
        <el-table-column prop="marketPrice" label="票价" min-width="100px"></el-table-column>
        <el-table-column prop="advanceDay" label="提前预订天数" min-width="100px"></el-table-column>
        <el-table-column prop="advanceTime" label="提前预订时间" min-width="100px"></el-table-column>
        <el-table-column prop="bookNotice" label="预订说明" min-width="100px">
          <template v-slot="{row}">
            <column-detail :content="row.bookNotice" :showHtmlFlag="true" :limit="1"></column-detail>
          </template>
        </el-table-column>
        <el-table-column prop="refundChangeRule" label="退改说明" min-width="100px">
          <template v-slot="{row}">
            <column-detail :content="row.refundChangeRule" :showHtmlFlag="true" :limit="1"></column-detail>
          </template>
        </el-table-column>
        <el-table-column prop="costDescription" label="费用说明" min-width="100px">
          <template v-slot="{row}">
            <column-detail :content="row.costDescription" :showHtmlFlag="true" :limit="1"></column-detail>
          </template>
        </el-table-column>
        <el-table-column prop="useDescription" label="使用说明" min-width="100px">
          <template v-slot="{row}">
            <column-detail :content="row.useDescription" :showHtmlFlag="true" :limit="1"></column-detail>
          </template>
        </el-table-column>
        <el-table-column prop="minBuyCount" label="起订张数" min-width="100px"></el-table-column>
        <el-table-column prop="maxBuyCount" label="最多预订张数" min-width="100px"></el-table-column>
        <el-table-column prop="unitQuantity" label="单位人数" min-width="100px"></el-table-column>
        <el-table-column prop="sortNum" label="排序" min-width="100px"></el-table-column>
        <el-table-column prop="status" label="状态" min-width="100px">
          <template slot-scope="{row}">
            <span v-if="row.status === 0">禁用</span>
            <span v-if="row.status === 1">正常</span>
          </template>
        </el-table-column>
        <el-table-column
            v-if="containPermissions(['cadminscenicticket:cadminscenicticket:update', 'cadminscenicticket:cadminscenicticket:delete'])"
            label="操作"
            min-width="120"
            fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaCAdminScenicTicketWindow.open('编辑平台自创景点门票', row)" icon="el-icon-edit" v-permissions="['cadminscenicticket:cadminscenicticket:update']">编辑</el-button>
<!--            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['cadminscenicticket:cadminscenicticket:delete']">删除</el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaCAdminScenicTicketWindow ref="operaCAdminScenicTicketWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaCAdminScenicTicketWindow from '@/components/admin/OperaCAdminScenicTicketWindow'
import ColumnDetail from '@/components/common/ColumnDetail'
export default {
  name: 'CAdminScenicTicket',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaCAdminScenicTicketWindow, ColumnDetail },
  data () {
    return {
      // 搜索
      searchForm: {
        scenicId: null,
        productName: '',
        status: ''
      }
    }
  },
  created () {
    this.config({
      module: '门票',
      api: '/admin/cAdminScenicTicket',
      'field.id': 'productId',
      'field.main': 'productName'
    })
    this.search()
  }
}
</script>
