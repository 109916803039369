<template>
  <TableLayout :permissions="['pointsluckdrawrecord:pointsluckdrawrecord:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="手机号" prop="memberMobile">
        <el-input v-model="searchForm.memberMobile" placeholder="请输入中奖用户手机号" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="中奖核销码" prop="winNum">
        <el-input v-model="searchForm.winNum" placeholder="请输入中奖核销码" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="奖品名称" prop="prizeName">
        <el-input v-model="searchForm.prizeName" placeholder="请输入奖品名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="是否中奖" prop="winFlag">
        <rx-select v-model="searchForm.winFlag" :data-list="[{code:1,label:'已中奖'},{code:0,label:'未中奖'}]"  ></rx-select>
      </el-form-item>
      <el-form-item label="是否过期" prop="expired">
        <rx-select v-model="searchForm.expired" :data-list="[{code:1,label:'已过期'},{code:0,label:'未过期'}]"  ></rx-select>
      </el-form-item>
      <el-form-item label="是否核销" prop="validFlag">
        <rx-select v-model="searchForm.validFlag" :data-list="[{code:true,label:'已核销'},{code:false,label:'未核销'}]"  ></rx-select>
      </el-form-item>

      <section>
        <el-button type="primary" @click="search">搜索</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
      >
        <el-table-column prop="userId" label="用户ID" min-width="100px"></el-table-column>
        <el-table-column prop="memberMobile" label="用户手机号" min-width="100px"></el-table-column>
        <el-table-column prop="prizeName" label="奖品名称" min-width="100px"></el-table-column>
        <el-table-column prop="winFlag" label="状态" min-width="100px">
          <template slot-scope="{row}">
            <span v-if="row.winFlag === 1">已中奖</span>
            <span v-if="row.winFlag === 0">未中奖</span>
          </template>
        </el-table-column>
        <el-table-column prop="winNum" label="核销码" min-width="180px"></el-table-column>
        <el-table-column prop="expired" label="是否过期" min-width="100px">
          <template slot-scope="{row}">
            <span v-if="row.expired === 1">已过期</span>
            <span v-if="row.expired === 0">未过期</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="抽奖时间" min-width="100px"></el-table-column>
        <el-table-column prop="usePrizeTime" label="核销时间" min-width="100px"></el-table-column>
        <el-table-column label="操作" width="120px">
          <template slot-scope="{row}">
            <el-button @click="verification(row)" v-if="!row.usePrizeTime" type="text">核销</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
      >
      </pagination>
    </template>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
export default {
  name: 'PointsLuckDrawRecord',
  extends: BaseTable,
  components: { TableLayout, Pagination },
  data () {
    return {
      // 搜索
      searchForm: {
        memberMobile: '',
        prizeName: '',
        winFlag: '',
        expired: null,
        winNum: '',
        validFlag: null
      }
    }
  },
  created () {
    this.config({
      module: '抽奖记录表',
      api: '/admin/pointsLuckDrawRecord',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  },
  methods: {
    verification (row) {
      this.$dialog.confirmBox('确定核销【' + row.winNum + '】吗？').then(res => {
        this.api.verification(row.id).then(res => {
          this.$message.success('操作成功！')
          this.handlePageChange()
        })
      }).catch(res => {

      })
    }
  }
}
</script>
