<template>
  <TableLayout :permissions="['sysbanner:sysbanner:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="所属模块" prop="module">
        <rx-select v-model="searchForm.module" type="banner_module"  ></rx-select>
      </el-form-item>
      <el-form-item label="操作类型" prop="operate">
        <rx-select v-model="searchForm.operate" type="banner_operate"  ></rx-select>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <rx-select v-model="searchForm.status" :data-list="[{code:1,label:'启用'},{code:0,label:'禁用'}]"  ></rx-select>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['sysbanner:sysbanner:create', 'sysbanner:sysbanner:delete']">
        <li><el-button type="primary" @click="$refs.operaSysBannerWindow.open('新建banner')" icon="el-icon-plus" v-permissions="['sysbanner:sysbanner:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['sysbanner:sysbanner:delete']">删除</el-button></li>
      </ul>
      <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
          @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="module" label="所属模块" min-width="100px">
          <template v-slot="{row}">
            {{getDictName('banner_module', row.module)}}
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题" min-width="100px"></el-table-column>
        <el-table-column prop="subTitle" label="二级标题" min-width="100px"></el-table-column>
        <el-table-column prop="url" label="图片地址" min-width="100px">
          <template slot-scope="{row}">
            <template v-if="!!row.url">
              <el-image fit="contain"
                        style="width: 80px; height: 80px"
                        :src="row.url"
                        :preview-src-list="[row.url]">
              </el-image>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="operate" label="点击之后的操作" min-width="100px">
          <template v-slot="{row}">
            {{getDictName('banner_operate', row.operate)}}
          </template>
        </el-table-column>
        <el-table-column prop="operateValue" label="操作之后的值" min-width="100px"></el-table-column>
        <el-table-column prop="status" label="状态" min-width="100px">
          <template slot-scope="{row}">
            <span v-if="row.status === 0"> 禁用 </span>
            <span v-if="row.status === 1"> 启用 </span>
          </template>
        </el-table-column>
        <el-table-column prop="sortNum" label="排序值（越小越靠前）" min-width="100px"></el-table-column>
        <el-table-column
            v-if="containPermissions(['sysbanner:sysbanner:update', 'sysbanner:sysbanner:delete'])"
            label="操作"
            min-width="120"
            fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaSysBannerWindow.open('编辑banner', row)" icon="el-icon-edit" v-permissions="['sysbanner:sysbanner:update']">编辑</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['sysbanner:sysbanner:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaSysBannerWindow ref="operaSysBannerWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaSysBannerWindow from '@/components/admin/OperaSysBannerWindow'
export default {
  name: 'SysBanner',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaSysBannerWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        module: '',
        operate: '',
        status: ''
      }
    }
  },
  created () {
    this.config({
      module: 'banner',
      api: '/admin/sysBanner',
      'field.id': 'id',
      'field.main': 'id',
      dictKeys: ['banner_module', 'banner_operate']
    })
    this.search()
  }
}
</script>
