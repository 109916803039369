import request from '@/utils/request'

export function fetchList () {
  return request.post('/sysConfig/query')
}

export function updateConfig (data) {
  return request.post('/sysConfig/update', data, {
    trim: true
  })
}
