import request from '@/utils/request'

// 查询
export function fetchList (data) {
    return request.post('/cNote/page', data, {
        trim: true
    })
}

export function auditPass (id) {
    return request.post('/cNote/pass/'+id)
}
