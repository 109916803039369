<template>
  <TableLayout :permissions="['cadminscenic:cadminscenic:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="景点名称" prop="scenicName">
        <el-input v-model="searchForm.scenicName" placeholder="请输入景点名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="所在省份" prop="provinceName">
        <el-input v-model="searchForm.provinceName" placeholder="请输入所在省份" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="所在城市" prop="cityName">
        <el-input v-model="searchForm.cityName" placeholder="请输入所在城市" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <rx-select v-model="searchForm.status" :data-list="[{code:1,label:'正常'},{code:0,label:'禁用'}]"  ></rx-select>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['cadminscenic:cadminscenic:create', 'cadminscenic:cadminscenic:delete']">
        <li><el-button type="primary" @click="$refs.operaCAdminScenicWindow.open('新建景点')" icon="el-icon-plus" v-permissions="['cadminscenic:cadminscenic:create']">新建</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column prop="scenicName" label="景点名称" min-width="100px"></el-table-column>
        <el-table-column prop="provinceName" label="所在省份" min-width="100px"></el-table-column>
        <el-table-column prop="cityName" label="所在城市" min-width="100px"></el-table-column>
        <el-table-column prop="scenicAddress" label="景点地址" min-width="100px"></el-table-column>
        <el-table-column prop="longitude" label="经度" min-width="100px"></el-table-column>
        <el-table-column prop="latitude" label="纬度" min-width="100px"></el-table-column>
        <el-table-column prop="image" label="图片" min-width="100px">
          <template slot-scope="{row}">
            <template v-if="!!row.image">
              <el-image fit="contain"
                        style="width: 80px; height: 80px"
                        :src="row.image.split(',')[0]"
                        :preview-src-list="row.image.split(',')">
              </el-image>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="star" label="星级" min-width="100px"></el-table-column>
        <el-table-column prop="sortNum" label="排序" min-width="100px"></el-table-column>
        <el-table-column prop="openTime" label="营业时间" min-width="100px"></el-table-column>
        <el-table-column prop="themeGroups" label="主题标签" min-width="100px"></el-table-column>
        <el-table-column prop="scenicDescription" label="景点描述" min-width="100px">
          <template v-slot="{row}">
            <column-detail :content="row.scenicDescription" :showHtmlFlag="true"></column-detail>
          </template>
        </el-table-column>
        <el-table-column prop="recommend" label="推荐信息" min-width="100px">
          <template v-slot="{row}">
            <column-detail :content="row.recommend" :showHtmlFlag="true"></column-detail>
          </template>
        </el-table-column>
        <el-table-column prop="bookNotice" label="预订须知" min-width="100px">
          <template v-slot="{row}">
            <column-detail :content="row.bookNotice" :showHtmlFlag="true"></column-detail>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" min-width="100px">
          <template slot-scope="{row}">
            <span v-if="row.status === 0">禁用</span>
            <span v-if="row.status === 1">正常</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="containPermissions(['cadminscenic:cadminscenic:update', 'cadminscenic:cadminscenic:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="$refs.operaCAdminScenicWindow.open('编辑平台自创景点', row)" icon="el-icon-edit" v-permissions="['cadminscenic:cadminscenic:update']">编辑</el-button>
<!--            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['cadminscenic:cadminscenic:delete']">删除</el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaCAdminScenicWindow ref="operaCAdminScenicWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaCAdminScenicWindow from '@/components/admin/OperaCAdminScenicWindow'
import ColumnDetail from '@/components/common/ColumnDetail'
export default {
  name: 'CAdminScenic',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaCAdminScenicWindow, ColumnDetail },
  data () {
    return {
      // 搜索
      searchForm: {
        scenicName: '',
        provinceName: '',
        cityName: '',
        status: ''
      }
    }
  },
  created () {
    this.config({
      module: '景点',
      api: '/admin/cAdminScenic',
      'field.id': 'id',
      'field.main': 'scenicName'
    })
    this.search()
  }
}
</script>
