import RxSelect from '../components/diy/RxSelect'
import RxUpload from '../components/diy/RxUpload'
import RxEditor from '../components/diy/RxEditor'

function vComponent (Vue) {
  // 判断组件组件是否已安装
  if (vComponent.installed) {
    return
  }
  Vue.component('RxSelect', RxSelect)
  Vue.component('RxUpload', RxUpload)
  Vue.component('RxEditor', RxEditor)
}

// 导出组件方法
export default vComponent
