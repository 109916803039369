import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/cOrderMerchant/page', data, {
    trim: true
  })
}

// 退款
export function passRefund (orderNo) {
  return request.post('/cOrderMerchant/passRefund/' + orderNo)
}

// 对账
export function bill (data) {
  return request.post('/cOrderMerchant/bill/', data)
}

// 导出Excel
export function exportExcel (data) {
  return request.post('/cOrderMerchant/exportExcel', data, {
    trim: true,
    download: true
  })
}
