import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/cUser/page', data, {
    trim: true
  })
}

// 启用/禁用
export function enableUser (userId, status) {
  return request.post(`/cUser/enable/${userId}/${status}`)
}

// 发放优惠券
export function giveCoupon (data) {
  return request.post('/cUser/giveCoupon', data, {
    trim: true
  })
}

// 查询商圈
export function busList () {
  return request.post('/cUser/busList')
}

// 根据商圈查询商户
export function merchantList (id) {
  return request.post(`/cUser/merchantList/${id}`)
}
