<template>
  <GlobalWindow
      :title="title"
      :visible.sync="visible"
      :confirm-working="isWorking"
      @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="商圈" prop="districtId" required>
        <rx-select v-model="form.districtId" pingying pre-load type="busList" placeholder="请选择商圈，支持拼音搜索" ></rx-select>
      </el-form-item>
      <el-form-item label="商铺名称" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入商铺名称" v-trim/>
      </el-form-item>
      <el-form-item label="商铺的标签" prop="label">
        <el-input v-model="form.label" placeholder="请输入商铺的标签" v-trim/>
      </el-form-item>
      <el-form-item label="商铺背景图" prop="backUrl">
        <rx-upload v-model="form.backUrl" type="10"></rx-upload>
      </el-form-item>
      <el-form-item label="经度" prop="longitude">
        <el-input v-model="form.longitude" placeholder="请输入经度" v-trim/>
      </el-form-item>
      <el-form-item label="纬度" prop="latitude">
        <el-input v-model="form.latitude" placeholder="请输入纬度" v-trim/>
      </el-form-item>
      <el-form-item label="详细地址" prop="fullAddress" required>
        <el-input v-model="form.fullAddress" placeholder="请输入详细地址" v-trim/>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <el-input v-model="form.phone" placeholder="请输入联系电话" v-trim/>
      </el-form-item>
      <el-form-item label="商铺营业开始时间" prop="openTime" required>
        <el-input v-model="form.openTime" placeholder="请输入商铺营业开始时间" v-trim/>
      </el-form-item>
      <el-form-item label="商铺结束营业时间" prop="endTime" required>
        <el-input v-model="form.endTime" placeholder="请输入商铺结束营业时间" v-trim/>
      </el-form-item>
      <el-form-item label="所属楼层" prop="floor">
        <el-input v-model="form.floor" placeholder="请输入所属楼层" v-trim/>
      </el-form-item>
      <el-form-item label="排序(越小越靠前)" prop="sortNum" required>
        <el-input v-model="form.sortNum" placeholder="请输入排序" v-trim/>
      </el-form-item>
      <el-form-item label="状态" prop="status" required>
        <rx-select v-model="form.status" :data-list="[{code:1,label:'正常'},{code:0,label:'禁用'}]"  ></rx-select>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaCMerchantWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        districtId: '',
        name: '',
        label: '',
        backUrl: '',
        longitude: '',
        latitude: '',
        fullAddress: '',
        phone: '',
        openTime: '',
        endTime: '',
        floor: '',
        sortNum: '',
        status: ''
      },
      // 验证规则
      rules: {
        districtId: [
          { required: true, message: '请选择商圈' }
        ],
        name: [
          { required: true, message: '请输入商铺名称' }
        ],
        fullAddress: [
          { required: true, message: '请输入详细地址' }
        ],
        openTime: [
          { required: true, message: '请输入商铺营业开始时间' }
        ],
        endTime: [
          { required: true, message: '请输入商铺结束营业时间' }
        ],
        sortNum: [
          { required: true, message: '请输入排序' }
        ],
        status: [
          { required: true, message: '请选择状态' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/admin/cMerchant',
      'field.id': 'id'
    })
  }
}
</script>
