<template>
  <div class="home">

  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {}
  }
}
</script>
