<template>
  <GlobalWindow
      :title="title"
      :visible.sync="visible"
      :confirm-working="isWorking"
      @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="奖品名称" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入奖品名称" v-trim />
      </el-form-item>
      <el-form-item label="奖品等级" prop="goodLevel" required>
        <el-input v-model="form.goodLevel" placeholder="请输入奖品等级" v-trim />
      </el-form-item>
      <el-form-item label="奖品价格" prop="price" required>
        <el-input v-model="form.price" placeholder="请输入奖品价格" type="number" v-trim/>
      </el-form-item>
      <el-form-item label="奖品库存" prop="stock" required>
        <el-input v-model="form.stock" placeholder="请输入奖品库存" type="number" v-trim />
      </el-form-item>
      <el-form-item label="中奖概率" prop="probability" required>
        <el-input v-model="form.probability" placeholder="请输入奖品库存" type="number" v-trim />
      </el-form-item>
      <el-form-item label="概率基数" prop="baseNum" required>
        <el-input v-model="form.baseNum" placeholder="请输入中奖概率基数" type="number" v-trim/>
      </el-form-item>
      <el-form-item label="说明">
        <div>1：所有奖品中奖概率加起来为<span class="red">1</span></div>
        <div>2：中奖为 “<span class="red">中奖概率(占比大)</span>” 和 “<span class="red">概率基数(占比小)</span>” 共同决定</div>
        <div>3：<span class="red">奖品库存字段</span>为 “库存模式”需要设置的，库存模式会扣减库存，<span class="red">有效期模式此字段无效</span></div>
        <div>4：<span class="red">概率基数字段</span>为 “有效期模式”需要设置的，越大中奖概率越高，<span class="red">库存模式此字段无效</span></div>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaPointsLuckDrawPrizeWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        name: '',
        goodLevel: '',
        stock: '',
        price: '',
        baseNum: '',
        probability: null
      },
      // 验证规则
      rules: {
        name: [
          { required: true, message: '请输入奖品名称' }
        ],
        goodLevel: [
          { required: true, message: '请输入奖品等级' }
        ],
        price: [
          { required: true, message: '请输入奖品价格' }
        ],
        stock: [
          { required: true, message: '请输入奖品库存' }
        ],
        probability: [
          { required: true, message: '请输入中奖概率' }
        ],
        baseNum: [
          { required: true, message: '请输入概率基数' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/admin/pointsLuckDrawPrize',
      'field.id': 'id'
    })
  }
}
</script>
<style scoped>
.red{
  color: red;
}
</style>
