<template>
  <TableLayout :permissions="['cordertrip:cordertrip:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="用户id" prop="userId">
        <el-input v-model="searchForm.userId" placeholder="请输入用户id" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="平台订单号" prop="platformNo">
        <el-input v-model="searchForm.platformNo" placeholder="请输入平台订单号" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
      >
        <el-table-column prop="userId" label="用户id" min-width="180px"></el-table-column>
        <el-table-column prop="platformNo" label="平台订单号" min-width="260px"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="180px"></el-table-column>
        <el-table-column prop="orderStatus" label="订单状态" min-width="100px">
          <template v-slot="{row}">
            {{getDictName('order_trip_order_type', row.orderStatus)}}
          </template>
        </el-table-column>
        <el-table-column prop="payTime" label="支付时间" min-width="180px"></el-table-column>
        <el-table-column prop="userName" label="联系人姓名" min-width="100px"></el-table-column>
        <el-table-column prop="userPhone" label="联系电话" min-width="100px"></el-table-column>
        <el-table-column prop="discount" label="优惠金额" min-width="100px"></el-table-column>
        <el-table-column prop="payPrice" label="实付金额" min-width="100px"></el-table-column>
        <el-table-column
            label="操作"
            min-width="120"
            fixed="right"
        >
          <template slot-scope="{row}">
            <el-button v-if="row.orderStatus === 4" type="text" @click="$refs.refundAuditWindow.open(row.platformNo, row.id, 4)">审核</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
      >
      </pagination>
    </template>

    <!-- 审核-->
    <RefundAuditWindow ref="refundAuditWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import RefundAuditWindow from '@/components/admin/RefundAuditWindow'
export default {
  name: 'COrderTrip',
  extends: BaseTable,
  components: { TableLayout, Pagination, RefundAuditWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        platformNo: '',
        userId: '',
        orderStatus: ''
      }
    }
  },
  created () {
    this.config({
      module: '行程订单表',
      api: '/admin/cOrderTrip',
      'field.id': 'id',
      'field.main': 'id',
      dictKeys: ['order_trip_order_type']
    })
    this.search()
  }
}
</script>
