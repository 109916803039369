<template>
  <TableLayout :permissions="['cuser:cuser:query']">
    <!-- 搜索表单 -->
    <div slot="search-form">
      <el-form ref="searchForm" :model="searchForm" label-width="100px" inline>
        <el-form-item label="景点名称" prop="loginName">
          <el-input v-model="searchForm.scenicName" placeholder="请输入景区名称" @keypress.enter.native="search"></el-input>
        </el-form-item>
        <section>
          <el-button type="primary" @click="search">搜索</el-button>
        </section>
      </el-form>
    </div>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
      >
        <el-table-column prop="scenicId" label="景点ID" min-width="100px"></el-table-column>
        <el-table-column prop="scenicName" label="景点名称" min-width="100px"></el-table-column>
        <el-table-column prop="provinceName" label="省" width="100px"></el-table-column>
        <el-table-column prop="cityName" label="市" width="100px"></el-table-column>
        <el-table-column prop="address" label="地址" min-width="100px"></el-table-column>
        <el-table-column prop="themeGroups" label="标签" min-width="100px"></el-table-column>
        <el-table-column prop="star" label="星级" width="100px"></el-table-column>
        <el-table-column prop="openTime" label="营业时间" min-width="100px"></el-table-column>
        <el-table-column prop="viewNum" label="浏览量" width="100px"></el-table-column>
      </el-table>
      <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
      >
      </pagination>
    </template>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
export default {
  name: 'CUser',
  extends: BaseTable,
  components: { TableLayout, Pagination },
  data () {
    return {
      // 搜索
      searchForm: {
        scenicName: null
      }
    }
  },
  created () {
    this.config({
      module: '用户表',
      api: '/admin/scenic',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
