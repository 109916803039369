<template>
  <GlobalWindow
      :title="title"
      :visible.sync="visible"
      :confirm-working="isWorking"
      @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="使用场景" prop="useType" required>
        <rx-select v-model="form.useType" type="user_coupon_use_type" pre-load></rx-select>
      </el-form-item>
      <el-form-item label="券的过期时间（领取之后多少天过期）" prop="receiveExpirNum" required>
        <el-input v-model="form.receiveExpirNum" placeholder="请输入券的过期时间（领取之后多少天过期）" v-trim/>
      </el-form-item>
      <el-form-item label="折扣类型" prop="randomType" required>
        <rx-select v-model="form.randomType" type="user_coupon_dis_type" pre-load></rx-select>
      </el-form-item>
      <el-form-item label="折扣值（根据折扣类型输入 0.95 或 20）" prop="randomDiscount" required>
        <el-input v-model="form.randomDiscount" placeholder="请输入折扣/金额" v-trim/>
      </el-form-item>
      <el-form-item label="券的张数" prop="couponNum" required>
        <el-input v-model="form.couponNum" placeholder="请输入券的张数" v-trim/>
      </el-form-item>
      <el-form-item label="券名称" prop="couponName" required>
        <el-input v-model="form.couponName" placeholder="请输入券名称" v-trim/>
      </el-form-item>
      <el-form-item label="消费门槛（无门槛为0）" prop="consumeThreshold" required>
        <el-input v-model="form.consumeThreshold" placeholder="请输入消费门槛（无门槛为0）" v-trim/>
      </el-form-item>
      <el-form-item label="描述" prop="description">
        <el-input v-model="form.description" type="textarea" placeholder="请输入券的描述" :rows="3" v-trim maxlength="350" show-word-limit />
      </el-form-item>
      <el-form-item label="状态" prop="status" required>
        <rx-select v-model="form.status" :data-list="[{code:1,label:'启用'},{code:0,label:'停用'}]" pre-load></rx-select>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaCRegisterCouponWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        useType: '',
        receiveExpirNum: '',
        randomType: '',
        randomDiscount: '',
        couponNum: '',
        couponName: '',
        consumeThreshold: '',
        description: '',
        status: '',
        deleted: '',
        createTime: '',
        createId: '',
        updateTime: '',
        updateId: ''
      },
      // 验证规则
      rules: {
        useType: [
          { required: true, message: '请选择使用场景' }
        ],
        receiveExpirNum: [
          { required: true, message: '请输入券的过期时间（领取之后多少天过期）' }
        ],
        randomType: [
          { required: true, message: '请选择券的折扣类型' }
        ],
        randomDiscount: [
          { required: true, message: '请输入折扣/金额' }
        ],
        couponNum: [
          { required: true, message: '请输入券的张数' }
        ],
        couponName: [
          { required: true, message: '请输入券名称' }
        ],
        consumeThreshold: [
          { required: true, message: '请输入消费门槛（无门槛为0）' }
        ],
        status: [
          { required: true, message: '请输入状态' }
        ],
      }
    }
  },
  created () {
    this.config({
      api: '/admin/cRegisterCoupon',
      'field.id': 'id'
    })
  }
}
</script>
