<template>
  <div>
    <div style="border: 1px solid #ccc; margin-top: 10px">
      <!-- 工具栏 -->
      <Toolbar
          style="border-bottom: 1px solid #ccc"
          :editor="editor"
          :defaultConfig="toolbarConfig"
      />
      <!-- 编辑器 -->
      <Editor
          style="height: 400px; overflow-y: hidden"
          :defaultConfig="editorConfig"
          v-model="html"
          @onChange="onChange"
          @onCreated="onCreated"
      />
    </div>
  </div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import Cookies from 'js-cookie'

export default {
  name: 'MyEditor',
  components: { Editor, Toolbar },
  data () {
    return {
      editor: null,
      html: null,
      toolbarConfig: {
        // toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
        // excludeKeys: ["uploadImage", "uploadVideo"],
      },
      editorConfig: {
        placeholder: '请输入内容...',
        // autoFocus: false,
        MENU_CONF: {
          uploadImage: {
            server: '/api/tool/upload/help',
            // form-data fieldName ，默认值 'wangeditor-uploaded-image'
            fieldName: 'file',
            // 单个文件的最大体积限制，默认为 2M
            maxFileSize: 100000 * 1024 * 1024, // 100000M
            // 最多可上传几个文件，默认为 100
            maxNumberOfFiles: 10,
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: [],
            // 将 meta 拼接到 url 参数中，默认 false
            metaWithUrl: false,
            // 自定义增加 http  header
            headers: {
              'eva-auth-token': Cookies.get('eva-auth-token')
            },
            // 跨域是否传递 cookie ，默认为 false
            withCredentials: true,
            // 超时时间，默认为 10 秒
            timeout: 100 * 1000 // 5 秒
          },
          uploadVideo: {
            server: '/api/tool/upload/help',
            // form-data fieldName ，默认值 'wangeditor-uploaded-image'
            fieldName: 'file',
            // 单个文件的最大体积限制，默认为 2M
            maxFileSize: 100000 * 1024 * 1024, // 100000M
            // 最多可上传几个文件，默认为 100
            maxNumberOfFiles: 5,
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: [],
            // 将 meta 拼接到 url 参数中，默认 false
            metaWithUrl: false,
            // 自定义增加 http  header
            headers: {
              'eva-auth-token': Cookies.get('eva-auth-token')
            },
            // 跨域是否传递 cookie ，默认为 false
            withCredentials: true,
            // 超时时间，默认为 10 秒
            timeout: 100 * 1000 // 5 秒
          }
        }
      }
    }
  },
  props: {
    disable: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: null
    }
  },
  methods: {
    onCreated (editor) {
      this.editor = Object.seal(editor) // 【注意】一定要用 Object.seal() 否则会报错
      if (this.html) {
        this.editor.setHtml(this.html)
      }
    },
    onChange (editor) {
      const html = editor.getHtml()
      if (!!html && html !== '<p><br></p>') {
        this.$emit('input', html)
      }
    }
  },
  beforeDestroy () {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁 editor ，重要！！！
  },
  watch: {
    disable (disable) {
      if (disable) {
        this.editor.disable()
      } else {
        this.editor.enable()
      }
    },
    value: function (key) {
      if (!!key && key != '') {
        this.html = key
      } else {
        this.html = '<p><br></p>'
      }
    }
  }
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
