<template>
  <GlobalWindow
      :title="title"
      :visible.sync="visible"
      :confirm-working="isWorking"
      @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="所属景点" prop="scenicId" required>
        <rx-select v-model="form.scenicId" type="scenicList" pre-load></rx-select>
      </el-form-item>
      <el-form-item label="门票名称" prop="productName" required>
        <el-input v-model="form.productName" placeholder="请输入门票名称" v-trim/>
      </el-form-item>
      <el-form-item label="票价" prop="marketPrice" required>
        <el-input v-model="form.marketPrice" placeholder="请输入票价" v-trim/>
      </el-form-item>
      <el-form-item label="提前预订天数" prop="advanceDay" required>
        <el-input v-model="form.advanceDay" placeholder="请输入提前预订天数" type="number" v-trim/>
      </el-form-item>
      <el-form-item label="提前预订时间" prop="advanceTime" required>
        <el-input v-model="form.advanceTime" placeholder="请输入提前预订时间" v-trim/>
      </el-form-item>
      <el-form-item label="预订说明" prop="bookNotice" required>
        <rx-editor v-model="form.bookNotice"></rx-editor>
      </el-form-item>
      <el-form-item label="退改说明" prop="refundChangeRule" required>
        <rx-editor v-model="form.refundChangeRule"></rx-editor>
      </el-form-item>
      <el-form-item label="费用说明" prop="costDescription" required>
        <rx-editor v-model="form.costDescription"></rx-editor>
      </el-form-item>
      <el-form-item label="使用说明" prop="useDescription" required>
        <rx-editor v-model="form.useDescription"></rx-editor>
      </el-form-item>
      <el-form-item label="起订张数" prop="minBuyCount">
        <el-input v-model="form.minBuyCount" placeholder="请输入起订张数" v-trim/>
      </el-form-item>
      <el-form-item label="最多预订张数" prop="maxBuyCount">
        <el-input v-model="form.maxBuyCount" placeholder="请输入最多预订张数" v-trim/>
      </el-form-item>
      <el-form-item label="单位人数( 例如双人票时，此字段值可能为2)" prop="unitQuantity">
        <el-input v-model="form.unitQuantity" placeholder="请输入单位人数( 例如双人票时，此字段值可能为2)" v-trim/>
      </el-form-item>
      <el-form-item label="排序，越小越靠前" prop="sortNum" required>
        <el-input v-model="form.sortNum" placeholder="请输入排序，越小越靠前" v-trim type="number"/>
      </el-form-item>
      <el-form-item label="状态" prop="status" required>
        <rx-select v-model="form.status" :data-list="[{code:1,label:'正常'},{code:0,label:'禁用'}]"  ></rx-select>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaCAdminScenicTicketWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        productId: null,
        scenicId: '',
        productName: '',
        marketPrice: '',
        advanceDay: '',
        advanceTime: '',
        bookNotice: '',
        refundChangeRule: '',
        costDescription: '',
        useDescription: '',
        minBuyCount: '',
        maxBuyCount: '',
        unitQuantity: '',
        sortNum: '',
        status: '',
        deleted: '',
        createTime: '',
        createId: '',
        updateTime: '',
        updateId: ''
      },
      // 验证规则
      rules: {
        scenicId: [
          { required: true, message: '请输入景点id' }
        ],
        productName: [
          { required: true, message: '请输入门票名称' }
        ],
        marketPrice: [
          { required: true, message: '请输入票价' }
        ],
        advanceDay: [
          { required: true, message: '请输入提前预订天数' }
        ],
        advanceTime: [
          { required: true, message: '请输入提前预订时间' }
        ],
        bookNotice: [
          { required: true, message: '请输入预订说明' }
        ],
        refundChangeRule: [
          { required: true, message: '请输入退改说明' }
        ],
        costDescription: [
          { required: true, message: '请输入费用说明' }
        ],
        useDescription: [
          { required: true, message: '请输入使用说明' }
        ],
        sortNum: [
          { required: true, message: '请输入排序，越小越靠前' }
        ],
        status: [
          { required: true, message: '请输入状态（0禁用，1正常）' }
        ],
        deleted: [
          { required: true, message: '请输入是否删除' }
        ],
        createTime: [
          { required: true, message: '请选择创建时间' }
        ],
        createId: [
          { required: true, message: '请输入创建人' }
        ]
      }
    }
  },
  created () {
    this.config({
      api: '/admin/cAdminScenicTicket',
      'field.id': 'productId'
    })
  }
}
</script>
