import request from '@/utils/request'

// 查询
export function fetchList (data) {
    return request.post('/cRegisterCoupon/page', data, {
        trim: true
    })
}

// 创建
export function create (data) {
    return request.post('/cRegisterCoupon/create', data)
}

// 修改
export function updateById (data) {
    return request.post('/cRegisterCoupon/updateById', data)
}

// 删除
export function deleteById (id) {
    return request.get(`/cRegisterCoupon/delete/${id}`)
}

// 批量删除
export function deleteByIdInBatch (ids) {
    return request.get('/cRegisterCoupon/delete/batch', {
        params: {
            ids
        }
    })
}
