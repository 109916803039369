import request from '@/utils/request'

// 查询
export function fetchList (data) {
    return request.post('/pointsLuckDrawRecord/page', data, {
        trim: true
    })
}

export function verification (id) {
    return request.post('/pointsLuckDrawRecord/verification/'+id)
}
