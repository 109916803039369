<template>
  <TableLayout :permissions="['corderhotel:corderhotel:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="用户id" prop="userId">
        <el-input v-model="searchForm.userId" placeholder="请输入用户id" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="平台订单号" prop="platformNo">
        <el-input v-model="searchForm.platformNo" placeholder="请输入平台订单号" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="酒店名称" prop="hotelName">
        <el-input v-model="searchForm.hotelName" placeholder="请输入酒店名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="联系人姓名" prop="contactName">
        <el-input v-model="searchForm.contactName" placeholder="请输入订单联系人姓名" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="联系人手机号" prop="contactMobile">
        <el-input v-model="searchForm.contactMobile" placeholder="请输入订单联系人手机号" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
          v-loading="isWorking.search"
          :data="tableData.list"
          stripe
      >
        <el-table-column prop="userId" label="用户id" min-width="180px"></el-table-column>
        <el-table-column prop="platformNo" label="平台订单号" min-width="260px"></el-table-column>
        <el-table-column prop="hotelId" label="第三方酒店ID" min-width="100px"></el-table-column>
        <el-table-column prop="hotelName" label="酒店名称" min-width="180px"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="180px"></el-table-column>
        <el-table-column prop="orderStatus" label="订单状态" min-width="100px">
          <template v-slot="{row}">
            {{getDictName('order_hotel_order_type', row.orderStatus)}}
          </template>
        </el-table-column>
        <el-table-column prop="payTime" label="支付时间" min-width="180px"></el-table-column>
        <el-table-column prop="fullAddress" label="酒店地址" min-width="100px"></el-table-column>
        <el-table-column prop="roomNum" label="房间数量" min-width="100px"></el-table-column>
        <el-table-column prop="checkInDate" label="入住时间" min-width="100px"></el-table-column>
        <el-table-column prop="checkOutDate" label="离店时间" min-width="100px"></el-table-column>
        <el-table-column prop="guestNames" label="房客姓名" min-width="100px"></el-table-column>
        <el-table-column prop="arriveTime" label="最晚到店时间" min-width="100px"></el-table-column>
        <el-table-column prop="pricePerDay" label="房价单价" min-width="100px"></el-table-column>
        <el-table-column prop="discount" label="优惠券金额" min-width="100px"></el-table-column>
        <el-table-column prop="finalPayAmount" label="最终支付价格" min-width="100px"></el-table-column>
        <el-table-column prop="contactName" label="联系人姓名" min-width="100px"></el-table-column>
        <el-table-column prop="contactMobile" label="联系人手机号" min-width="100px"></el-table-column>
        <el-table-column prop="orderRemark" label="用户下单备注" min-width="100px"></el-table-column>
        <el-table-column prop="roomName" label="房型名称" min-width="100px"></el-table-column>
        <el-table-column prop="bedName" label="床型名称" min-width="100px"></el-table-column>
        <el-table-column prop="createTime" label="下单时间" min-width="100px"></el-table-column>
        <el-table-column prop="useableArea" label="可用面积" min-width="100px"></el-table-column>
        <el-table-column prop="capacity" label="最大容纳人数" min-width="100px"></el-table-column>
        <el-table-column prop="floor" label="房型所在楼层" min-width="100px"></el-table-column>
        <el-table-column prop="ratePlanName" label="产品名称" min-width="100px"></el-table-column>
        <el-table-column
            label="操作"
            min-width="120"
            fixed="right"
        >
          <template slot-scope="{row}">
            <el-button v-if="row.orderStatus === 10" type="text" @click="$refs.refundAuditWindow.open(row.platformNo, row.id, 1)">审核</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
      >
      </pagination>
    </template>

    <!-- 审核-->
    <RefundAuditWindow ref="refundAuditWindow" @success="handlePageChange"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import RefundAuditWindow from '@/components/admin/RefundAuditWindow'
export default {
  name: 'COrderHotel',
  extends: BaseTable,
  components: { TableLayout, Pagination, RefundAuditWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        userId: '',
        platformNo: '',
        hotelName: '',
        contactName: '',
        contactMobile: '',
        orderStatus: ''
      }
    }
  },
  created () {
    this.config({
      module: '酒店订单表',
      api: '/admin/cOrderHotel',
      'field.id': 'id',
      'field.main': 'id',
      dictKeys: ['order_hotel_order_type']
    })
    this.search()
  }
}
</script>
